import { Grid } from "@mui/material";
import React from "react";
import DashboardCard from "../../components/custom/dashboard/DashboardCard";
import ChartsOverview from "../../components/custom/dashboard/BarChart";
import StackedAreaChart from "../../components/custom/dashboard/AreaChart";
import FileHistoryCount from "../../components/dashboard_utils/FileHistoryCount";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";
import { DashboardOutlined, Home } from "@mui/icons-material";

const Dashboard = () => {
  const breadcrumbsData = [
    { icon: Home, title: "হোম" },
    { icon: DashboardOutlined, title: "ড্যাশবোর্ড", active: true },
  ];
  return (
    <>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} md={6}>
              <FileHistoryCount />
              {/* <DashboardCard
                icon={"tdesign:building"}
                title={"Active property"}
                subTitle={"Total"}
                countNumber={"65"}
                color={"#8854d0"}
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <DashboardCard
                icon={"fa:users"}
                title={"Users"}
                subTitle={"Total"}
                countNumber={"54"}
                color={"#ffa502"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DashboardCard
                icon={"tdesign:building"}
                title={"Active property"}
                subTitle={"Total"}
                countNumber={"65"}
                color={"#20bf6b"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DashboardCard
                icon={"tdesign:building"}
                title={"Active property"}
                subTitle={"Total"}
                countNumber={"65"}
                color={"#ff4757"}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="slideUp">
                <ChartsOverview />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="slideUp">
            <StackedAreaChart />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
