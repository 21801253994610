import React, { useEffect, useRef, useState } from "react";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";
import { Box } from "@mui/material";
import { FindInPage, Home, Print, Search } from "@mui/icons-material";
import { Button, Form, Table } from "react-bootstrap";
import { assets } from "../../assets";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/custom/Loader";
import { useLazyGetFileLocationSearchQuery } from "../../redux/api/backendUtilsApiSlice";
import { BeatLoader } from "react-spinners";
import NoteSheet from "../../components/NoteSheet";
import { useAuthorized } from "../../hooks/useAuthorized";
import { accessRoles } from "../../utils/accessRoles";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyrightKeyAttachArray } from "../../utils/CopyrightGenerateAttachArray";
import { useReactToPrint } from "react-to-print";
import { AgreementKeyAttachArray } from "../../utils/agreementGenerateAttachArray";
import AdminFullFormAgreement from "../../components/full-form-admin/AdminFullFormAgreement";
import AdminFullFormCopyright from "../../components/full-form-admin/AdminFullFormCopyright";

const validationSchema = Yup.object({
  comments: Yup.string()
    .required("মন্তব্য আবশ্যক")
    .min(10, "মন্তব্য কমপক্ষে ১০ অক্ষর হতে হবে"),
});

const ApplicationsTracking = () => {
  const printFullFormRef = useRef();
  const [previewData, setPreviewData] = useState("");
  const { hasRole } = useAuthorized();
  const [trackNo, setTrackNo] = React.useState("");
  const navigate = useNavigate();
  const printRef = useRef();

  // get query regTrack
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const regTrack = searchParams.get("regTrack");

  const [fetchFileData, { data, isLoading, error, isFetching }] =
    useLazyGetFileLocationSearchQuery();

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: FindInPage,
      title: hasRole([accessRoles.Register, accessRoles.CopyrightAdmin])
        ? `নথি সন্ধান`
        : `আবেদনের অবস্থান অনুসন্ধান`,
      active: true,
    },
  ];

  const handleSearch = () => {
    if (trackNo) {
      fetchFileData(trackNo);
      navigate(`/admin/applications-tracking?regTrack=${trackNo}`);
    }
  };

  useEffect(() => {
    if (regTrack) {
      setTrackNo(regTrack);
      fetchFileData(regTrack);
    }
  }, [regTrack]);

  const info = data?.data;
  const status = info?.comments?.[0]?.status;

  // Add condition to ensure that info is available before calling CopyrightKeyAttachArray
  const infoTable = status
    ? Number(info.apptype) === 1
      ? CopyrightKeyAttachArray({ st: Number(info.st), status })
      : AgreementKeyAttachArray({ st: Number(info.st), status })
    : null;

  const commentsNoteSheet = {
    noteSheetList: info?.comments,
    noteSheetValues: status,
  };

  const infoForNote = {
    noteNumber: info?.trackReg,
    applicantName: info?.name,
    workTitle: info?.workTitle,
    category: info?.catName,
    amount: info?.amount,
    challan: Number(info?.st) === 3 ? "চালান" : "ট্রেজারি",
    challanNumber: Number(info?.st) === 3 ? info?.echallanId : info?.treasuryNo,
    treasuryDate: info?.treasuryDate || new Date(),
  };

  const handleEditStepOne = () => {
    if (Number(info?.apptype) === 1) {
      navigate(`/admin/copyright-first-part-edit/${info.id}/${info.trackReg}`);
    } else {
      navigate(`/agreement-form-add?id=${info.id}`);
    }
  };

  const handleEditStepTwo = () => {
    if (Number(info?.apptype) === 1) {
      navigate(
        `/admin/copyright-second-part-edit/${info.pdDocId}/${info.trackReg}`
      );
    } else {
      navigate(`/agreement-form-add-two/${info.id}/${info?.pdDocId}/edit`);
    }
  };
  const handlePayment = () => {
    navigate(`/admin/treasury-payment-edit/${info.id}/${info.trackReg}`);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleFullFormPrint = useReactToPrint({
    content: () => printFullFormRef.current,
  });

  useEffect(() => {
    if (info) {
      setPreviewData({
        id: info.id,
        trackReg: info.trackReg,
      });
    }
  }, [info]);

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <div className="py-2">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="">ট্র্যাকিং নম্বর</label>
              <div className="d-flex gap-2">
                <Form.Control
                  value={trackNo}
                  onChange={(e) => setTrackNo(e.target.value)}
                />
                <Button
                  disabled={isLoading}
                  className="d-flex"
                  onClick={handleSearch}
                >
                  {isLoading || isFetching ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    <>
                      {" "}
                      <Search /> খুঁজুন
                    </>
                  )}
                </Button>
              </div>
            </div>
            {error && (
              <div className="p-3">
                <div className="p-4 card">
                  <label htmlFor="">
                    এই ট্র্যাকিং নম্বর / আবেদনকারীর নাম / কর্মের শিরোনাম সহ কোন
                    আবেদন পাওয়া যায়নি ।
                  </label>
                </div>
              </div>
            )}
            {info && !error && (
              <>
                <div className="col-md-12 mt-4">
                  <div className="card">
                    <div className="row">
                      <div className="d-flex align-items-center justify-content-center col-md-4">
                        <div className="p-3 text-center card m-3">
                          <img
                            src={info?.image || assets.avatar}
                            className="rounded"
                            alt=""
                          />
                          <div className="mt-3">
                            <label htmlFor="" className="border rounded p-2">
                              ট্র্যাকিং নম্বর - {info?.trackReg}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="p-3">
                          <div className="text-left">
                            <h5 className="fw-bold">
                              {Number(info?.apptype) === 1
                                ? "কপিরাইটের"
                                : "চুক্তিপত্রের"}{" "}
                              আবেদন
                            </h5>
                          </div>
                          <div className="mt-4">
                            <Table striped bordered>
                              <tbody>
                                <tr>
                                  <th>নথির অবস্থান</th>
                                  <td>
                                    <span className="fw-semibold">
                                      {info?.fileStatusName}
                                    </span>{" "}
                                    <br /> {info?.admin?.name} <br />{" "}
                                    {info?.admin?.role?.bnName}
                                  </td>
                                </tr>
                                <tr>
                                  <th>আবেদনকারীর নাম</th>
                                  <td>{info?.name}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের শ্রেণি</th>
                                  <td>{info?.catName}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের উপশ্রেণি</th>
                                  <td>{info?.subCatName}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের শিরোনাম</th>
                                  <td>{info?.workTitle}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  {hasRole([accessRoles.CopyrightAdmin]) && (
                    <div className="card">
                      <div className="d-flex gap-3 justify-content-center my-3">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={handleEditStepOne}
                        >
                          ১ম অংশ
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={handleEditStepTwo}
                        >
                          ২ম অংশ
                        </Button>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={handlePayment}
                        >
                        {Number(info?.st) === 2 ? "ট্রেজারি" : "ই-চালান"} পেমেন্ট
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setPreviewData({
                              id: info.id,
                              trackReg: info?.trackReg,
                            });
                            handleFullFormPrint();
                          }}
                        >
                          <Print /> প্রিন্ট করুন
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {hasRole([
                  accessRoles.CopyrightAdmin,
                  accessRoles.Register,
                ]) && (
                  <>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <Formik
                            initialValues={{ comments: "" }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                              console.log(values);
                              // Handle form submission here (e.g., API call)
                            }}
                          >
                            {({
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              errors,
                              touched,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="bg-slate p-2 fw-semibold">
                                  রেজিস্ট্রারের মন্তব্য
                                </div>

                                <div className="mt-2">
                                  <label
                                    className="my-2 fw-semibold"
                                    htmlFor="comments"
                                  >
                                    মন্তব্য
                                  </label>
                                  <Form.Control
                                    name="comments"
                                    placeholder="এখানে লিখুন..."
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    as="textarea"
                                    rows={6}
                                    value={values.comments}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.comments && !!errors.comments
                                    }
                                  />
                                  {touched.comments && errors.comments ? (
                                    <div className="text-danger">
                                      {errors.comments}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="text-center my-3">
                                  <Button variant="primary" type="submit">
                                    দাখিল করুন
                                  </Button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between align-items-center bg-slate p-2 ">
                            <div className="fw-semibold">সম্পূর্ণ নোট শীট</div>

                            <span onClick={handlePrint}>
                              <Print className="text-primary fs-4 cursor-pointer" />
                            </span>
                          </div>
                          {status && (
                            <div className="mt-3">
                              <div
                                className="card my-2 p-2"
                                style={{ height: "800px", overflow: "auto" }}
                              >
                                <NoteSheet
                                  commentsNoteSheet={commentsNoteSheet}
                                  info={infoForNote}
                                  infoTable={infoTable}
                                  printRef={printRef}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {previewData && data?.data && (
        <>
          {Number(info?.apptype) === 1 ? (
            <AdminFullFormCopyright
              printRef={printFullFormRef}
              info={previewData}
            />
          ) : (
            <AdminFullFormAgreement
              printRef={printFullFormRef}
              info={previewData}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ApplicationsTracking;
