import { FILE_LOCATION_SEARCH } from "../constants";
import { apiSlice } from "./apiSlice";

const backendUtilsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // FILE_LOCATION_SEARCH
    getFileLocationSearch: builder.query({
      query: (trackReg) => `${FILE_LOCATION_SEARCH}?trackReg=${trackReg}`,
    }),
  }),
});

export const { useLazyGetFileLocationSearchQuery } = backendUtilsApiSlice;
