import {
  AGREEMENT_REGISTER_BOOKS,
  AGREEMENT_SHOW_FULL_FORM,
  AGREEMENT_TREASURY_VERIFY,
  APPLICATION_REGISTER_BOOKS,
  APPLICATION_SHOW_FULL_FORM,
  ASSISTANT_EXAMINER_VERIFY,
  ASSISTANT_REGISTRAR_VERIFY,
  CATALOG_ATTENTION_MESSAGES,
  CATEGORY_COUNT_AGREEMENT,
  CATEGORY_COUNT_COPYRIGHT,
  CERTIFICATE_ARTIST_WORK,
  CERTIFICATE_ATTENTION_UPDATE,
  CERTIFICATE_EDIT_PERMISSION,
  CERTIFICATE_ISSUE_ENDORSE,
  CERTIFICATE_LANGUAGE,
  CERTIFICATE_LANGUAGE_UPDATE,
  CERTIFICATE_PREPARE,
  CERTIFICATE_SHOW,
  CERTIFICATE_TYPE_UPDATE,
  CERTIFICATES_LIST,
  COPYRIGHT_TREASURY_VERIFY,
  CORRECTION_AGREEMENT_SENT,
  CORRECTION_COPYRIGHT_SENT,
  CORRECTION_LETTER_APPROVED,
  CORRECTION_LETTER_SEND_FOR_APPROVAL,
  CORRECTION_LETTER_SEND_TO_APPLICANT,
  CORRECTION_LETTER_SHOW_AGREEMENT,
  CORRECTION_LETTER_SHOW_COPYRIGHT,
  DEPUTY_REGISTRAR_VERIFY,
  ECHALAN_VERIFY,
  ENDORSE_PREPARE,
  ENDORSE_SHOW,
  ENDORSES_LIST,
  EXAMINER_VERIFY,
  HEARING_AGREEMENT_SENT,
  HEARING_COMPLAINANT_DELETE,
  HEARING_COPYRIGHT_SENT,
  HEARING_LETTER_APPROVED,
  HEARING_LETTER_SEND_FOR_APPROVAL,
  HEARING_LETTER_SEND_TO_APPLICANT,
  HEARING_LETTER_SHOW_AGREEMENT,
  HEARING_LETTER_SHOW_COMPLAINANT,
  HEARING_LETTER_SHOW_COPYRIGHT,
  HEARING_SCHEDULE_SENT,
  INDEXER_INITIAL_VERIFY,
  INDEXER_VERIFY,
  INSPECTOR_VERIFY_APPLICATIONS,
  INVESTIGATION_REPORT_SEND,
  ORIGINALITY_TESTING,
  ORIGINALITY_TESTING_COPYRIGHT,
  PROGRAMMER_VERIFY,
  RECORD_LETTER_APPROVED,
  RECORD_LETTER_SEND_FOR_APPROVAL,
  RECORD_LETTER_SEND_TO_APPLICANT,
  RECORD_LETTER_SHOW_AGREEMENT,
  RECORD_LETTER_SHOW_COPYRIGHT,
  REGISTRAR_VERIFY,
  REPLY_ADDITIONAL_EXPLANATION,
  SUBMITTED_APPLICATION_AGREEMENT,
  SUBMITTED_APPLICATION_COPYRIGHT,
  SUBMITTED_APPLICATION_DETAILS_AGREEMENT,
  SUBMITTED_APPLICATION_DETAILS_COPYRIGHT,
  TRANSFER_ADDITIONAL_EXPLANATION,
  TRANSFER_INVESTIGATION_SEND,
  UNAUTHORIZED_LETTER_APPROVED,
  UNAUTHORIZED_LETTER_SEND_FOR_APPROVAL,
  UNAUTHORIZED_LETTER_SEND_TO_APPLICANT,
  UNAUTHORIZED_LETTER_SHOW_AGREEMENT,
  UNAUTHORIZED_LETTER_SHOW_COPYRIGHT,
} from "../constants";
import { apiSlice } from "./apiSlice";

const submittedCopyrightAgreementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // COPYRIGHT
    getSubmittedCopyright: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = 1,
      }) => {
        let url = `${SUBMITTED_APPLICATION_COPYRIGHT}?per_page=10&fileStatus=${
          fileStatus || 1
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedCopyright"],
      keepUnusedDataFor: 1,
    }),
    // CorrectionForm copyright
    getCorrectionFormCopyright: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = 2,
      }) => {
        let url = `${SUBMITTED_APPLICATION_COPYRIGHT}?per_page=10&fileStatus=${
          fileStatus || 2
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedCopyright"],
      keepUnusedDataFor: 1,
    }),

    // submittedCopyright
    getSubmittedCopyrightDetails: builder.query({
      query: (id) => `${SUBMITTED_APPLICATION_DETAILS_COPYRIGHT}/${id}`,
    }),

    // AGREEMENT
    getSubmittedAgreement: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = 1,
      }) => {
        let url = `${SUBMITTED_APPLICATION_AGREEMENT}?per_page=10&fileStatus=${
          fileStatus || 1
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),

    // CORRECTION_COPYRIGHT_SENT

    getCorrectionSentCopyright: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${CORRECTION_COPYRIGHT_SENT}?per_page=10&fileStatus=${
          fileStatus || ""
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),
    // CORRECTION_AGREEMENT_SENT
    getCorrectionSentAgreement: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${CORRECTION_AGREEMENT_SENT}?per_page=10&fileStatus=${
          fileStatus || ""
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),
    // Hearing copyright
    getHearingSentCopyright: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${HEARING_COPYRIGHT_SENT}?per_page=10&fileStatus=${
          fileStatus || ""
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),
    // hearing agreement
    getHearingSentAgreement: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${HEARING_AGREEMENT_SENT}?per_page=10&fileStatus=${
          fileStatus || ""
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),

    // CorrectionForm AGREEMENT
    getCorrectionFormAgreement: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = 2,
      }) => {
        let url = `${SUBMITTED_APPLICATION_AGREEMENT}?per_page=10&fileStatus=${
          fileStatus || 2
        }`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["submittedAgreement"],
      keepUnusedDataFor: 1,
    }),

    // submittedAgreement
    getSubmittedAgreementDetails: builder.query({
      query: (id) => `${SUBMITTED_APPLICATION_DETAILS_AGREEMENT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // Correction Letter Agreement
    showCorrectionLetterAgreementDetails: builder.query({
      query: (id) => `${CORRECTION_LETTER_SHOW_AGREEMENT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // Correction Letter copyright
    showCorrectionLetterCopyrightDetails: builder.query({
      query: (id) => `${CORRECTION_LETTER_SHOW_COPYRIGHT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // Hearing Agreement
    showHearingAgreementDetails: builder.query({
      query: (id) => `${HEARING_LETTER_SHOW_AGREEMENT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // Hearing copyright
    showHearingCopyrightDetails: builder.query({
      query: (id) => `${HEARING_LETTER_SHOW_COPYRIGHT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),

    // recorded Agreement
    showRecordedAgreementDetails: builder.query({
      query: (id) => `${RECORD_LETTER_SHOW_AGREEMENT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // recorded copyright
    showRecordedCopyrightDetails: builder.query({
      query: (id) => `${RECORD_LETTER_SHOW_COPYRIGHT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),

    // Unauthorized Agreement
    showUnauthorizedAgreementDetails: builder.query({
      query: (id) => `${UNAUTHORIZED_LETTER_SHOW_AGREEMENT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    // Unauthorized copyright
    showUnauthorizedCopyrightDetails: builder.query({
      query: (id) => `${UNAUTHORIZED_LETTER_SHOW_COPYRIGHT}/${id}`,
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),

    // inspector verify
    verifySubmittedAgreement: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${INSPECTOR_VERIFY_APPLICATIONS}/${id}`,
        method: "POST",
        body: formData,
      }),
    }),
    // indexer verify
    verifyIndexer: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${INDEXER_VERIFY}/${id}`,
        method: "POST",
        body: formData,
      }),
    }),
    // /admin/assistant-examiner/verify/
    verifyAssistantExaminer: builder.mutation({
      query: ({ id, formData, role_id }) => {
        let url = ""; // Initialize url

        switch (role_id) {
          case 6:
            url = ASSISTANT_EXAMINER_VERIFY;
            break; // Add break to prevent fall-through
          case 5:
            url = EXAMINER_VERIFY;
            break; // Add break to prevent fall-through
          case 11:
            url = PROGRAMMER_VERIFY;
            break; // Add break to prevent fall-through
          case 3:
            url = ASSISTANT_REGISTRAR_VERIFY;
            break; // Add break to prevent fall-through
          case 4:
            url = DEPUTY_REGISTRAR_VERIFY;
            break; // Add break to prevent fall-through
          case 2:
            url = REGISTRAR_VERIFY;
            break; // Add break to prevent fall-through
          case 7:
            url = INDEXER_VERIFY;
            break;
          default:
            // Optionally handle the default case if needed
            throw new Error("Invalid role_id");
        }

        return {
          url: `${url}/${id}`,
          method: "POST",
          body: formData,
        };
      },
    }),

    // echalan verify
    verifyEchalan: builder.mutation({
      query: (formData) => ({
        url: ECHALAN_VERIFY,
        method: "POST",
        body: formData,
      }),
    }),
    //originality test
    originalityTest: builder.mutation({
      query: (formData) => ({
        url: ORIGINALITY_TESTING,
        method: "POST",
        body: formData,
      }),
    }),
    copyrightOriginalityTest: builder.mutation({
      query: (formData) => ({
        url: ORIGINALITY_TESTING_COPYRIGHT,
        method: "POST",
        body: formData,
      }),
    }),
    // indexer initial verify
    initialVerify: builder.mutation({
      query: ({ formData, id }) => ({
        url: INDEXER_INITIAL_VERIFY + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // indexer initial verify
    additionalExplanation: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        const url =
          fileStatus === 14
            ? INVESTIGATION_REPORT_SEND
            : REPLY_ADDITIONAL_EXPLANATION;
        return {
          url: url + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // indexer initial verify
    additionalExplanationTransfer: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        const url =
          fileStatus === 14
            ? TRANSFER_INVESTIGATION_SEND
            : TRANSFER_ADDITIONAL_EXPLANATION;
        return {
          url: url + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // CORRECTION_LETTER_SEND_FOR_APPROVAL
    correctionLetterApproval: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        const url =
          fileStatus === 7
            ? CORRECTION_LETTER_SEND_FOR_APPROVAL
            : fileStatus === 21
            ? CORRECTION_LETTER_APPROVED
            : fileStatus === 22
            ? CORRECTION_LETTER_SEND_TO_APPLICANT
            : null;
        return {
          url: url + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // recorded application
    recordedApproval: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        const url =
          fileStatus === 19
            ? RECORD_LETTER_SEND_FOR_APPROVAL
            : fileStatus === 27
            ? RECORD_LETTER_APPROVED
            : fileStatus === 28
            ? RECORD_LETTER_SEND_TO_APPLICANT
            : null;
        return {
          url: url + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // recorded application
    unauthorizedApproval: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        const url =
          fileStatus === 6
            ? UNAUTHORIZED_LETTER_SEND_FOR_APPROVAL
            : fileStatus === 29
            ? UNAUTHORIZED_LETTER_APPROVED
            : fileStatus === 30
            ? UNAUTHORIZED_LETTER_SEND_TO_APPLICANT
            : null;
        return {
          url: url + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // HEARING_LETTER_SEND_FOR_APPROVAL
    hearingApproval: builder.mutation({
      query: ({ formData, id, fileStatus }) => {
        console.log(fileStatus, HEARING_LETTER_SEND_TO_APPLICANT);
        const url =
          fileStatus === 10
            ? HEARING_LETTER_SEND_FOR_APPROVAL
            : fileStatus === 11
            ? HEARING_LETTER_APPROVED
            : fileStatus === 20
            ? HEARING_LETTER_SEND_TO_APPLICANT
            : null;
        const data = fileStatus !== 20 ? formData : "";
        return {
          url: url + "/" + id,
          method: "POST",
          body: data,
        };
      },
    }),
    // HEARING_SCHEDULE_SENT
    hearingScheduleSent: builder.mutation({
      query: ({ formData, id }) => {
        return {
          url: HEARING_SCHEDULE_SENT + "/" + id,
          method: "POST",
          body: formData,
        };
      },
    }),
    // / /admin/agreement/treasury/verify
    agreementTreasuryVerify: builder.mutation({
      query: (formData) => ({
        url: AGREEMENT_TREASURY_VERIFY,
        method: "POST",
        body: formData,
      }),
    }),
    // /admin/application/treasury/verify
    copyrightTreasuryVerify: builder.mutation({
      query: (formData) => ({
        url: COPYRIGHT_TREASURY_VERIFY,
        method: "POST",
        body: formData,
      }),
    }),
    //APPLICATION_SHOW_FULL_FORM
    showCopyrightFullForm: builder.query({
      query: ({ id, trackReg }) =>
        `${APPLICATION_SHOW_FULL_FORM}/${id}?trackReg=${trackReg ?? ""}`,
    }),

    //AGREEMENT_SHOW_FULL_FORM
    showAgreementFullForm: builder.query({
      query: ({ id, trackReg }) =>
        `${AGREEMENT_SHOW_FULL_FORM}/${id}?trackReg=${trackReg ?? ""}`,
    }),
    //HEARING_LETTER_SHOW_COMPLAINANT
    showHearingLetter: builder.query({
      query: (id) => `${HEARING_LETTER_SHOW_COMPLAINANT}/${id}`,
    }),
    // delete complainant
    deleteComplainant: builder.mutation({
      query: (id) => ({
        url: `${HEARING_COMPLAINANT_DELETE}/${id}`,
        method: "DELETE",
      }),
    }),

    // register book copyrights
    getRegBookCopyright: builder.query({
      query: ({ bookType, trackReg, indexBook, registration_no }) => {
        let url = `${APPLICATION_REGISTER_BOOKS}?bookType=${bookType}`;
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (indexBook) {
          url += `&indexBook=${indexBook}`;
        }
        if (registration_no) {
          url += `&registration_no=${registration_no}`;
        }
        return url;
      },
      keepUnusedDataFor: 1,
    }),
    // register book agreements
    getRegBookAgreement: builder.query({
      query: ({ bookType, trackReg, indexBook, registration_no }) => {
        let url = `${AGREEMENT_REGISTER_BOOKS}?bookType=${bookType}`;
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (indexBook) {
          url += `&indexBook=${indexBook}`;
        }
        if (registration_no) {
          url += `&registration_no=${registration_no}`;
        }
        return url;
      },
      keepUnusedDataFor: 1,
    }),
    // certificate list for copyrights
    getCertificateList: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${CERTIFICATES_LIST}?per_page=10`;
        if (fileStatus) {
          url += `&fileStatus=${fileStatus}`;
        }
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      keepUnusedDataFor: 1,
    }),
    // endorse list for agreement
    getEndorseList: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
        fileStatus = "",
      }) => {
        let url = `${ENDORSES_LIST}?per_page=10`;
        if (fileStatus) {
          url += `&fileStatus=${fileStatus}`;
        }
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      keepUnusedDataFor: 1,
    }),
    // agreement with categories
    getAgreementCategories: builder.query({
      query: (fileStatus) =>
        CATEGORY_COUNT_AGREEMENT + "?fileStatus=" + fileStatus,
      keepUnusedDataFor: 1,
    }),
    // copyright with categories
    getCopyrightCategories: builder.query({
      query: (fileStatus) =>
        CATEGORY_COUNT_COPYRIGHT + "?fileStatus=" + fileStatus,
      keepUnusedDataFor: 1,
    }),
    // get attention messages
    getAttentionMessages: builder.query({
      query: () => CATALOG_ATTENTION_MESSAGES + "?status=1&per_page=100",
      keepUnusedDataFor: 1,
    }),
    // CERTIFICATE_PREPARE
    prepareCertificate: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_PREPARE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // ENDORSE_PREPARE
    prepareEndorse: builder.mutation({
      query: ({ id, formData }) => ({
        url: ENDORSE_PREPARE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificate show
    showCertificate: builder.query({
      query: ({ id, user_id }) =>
        CERTIFICATE_SHOW + "/" + id + "?user_id=" + user_id,
      keepUnusedDataFor: 3,
    }),
    // endorse show
    showEndorse: builder.query({
      query: ({ id, user_id }) => ENDORSE_SHOW + "/" + id + "?user_id=" + user_id,
      keepUnusedDataFor: 3,
    }),
    // CERTIFICATE_EDIT_PERMISSION
    certificateEditPermission: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_EDIT_PERMISSION + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificateTypeUpdate
    certificateTypeUpdate: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_TYPE_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificate Lang Update
    certificateLangUpdate: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_LANGUAGE_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificate Attention Update
    certificateAttentionUpdate: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_ATTENTION_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificate Attention Update
    certificateArtistWorkUpdate: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_ARTIST_WORK + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // certificate Attention Update
    certificateIssueEndorse: builder.mutation({
      query: ({ id, formData }) => ({
        url: CERTIFICATE_ISSUE_ENDORSE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    // CERTIFICATE_LANGUAGE delete
    deleteCertificateLanguage: builder.mutation({
      query: ({ id1, id2 }) => ({
        url: CERTIFICATE_LANGUAGE + "/" + id1 + "/" + id2,
        method: "DELETE",
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useGetSubmittedCopyrightQuery,
  useGetCorrectionFormCopyrightQuery,
  useGetCorrectionFormAgreementQuery,
  useGetSubmittedCopyrightDetailsQuery,
  useGetSubmittedAgreementQuery,
  useGetSubmittedAgreementDetailsQuery,
  useVerifySubmittedAgreementMutation,
  useVerifyAssistantExaminerMutation,
  useVerifyIndexerMutation,
  useVerifyEchalanMutation,
  useOriginalityTestMutation,
  useCopyrightOriginalityTestMutation,
  useInitialVerifyMutation,
  useAdditionalExplanationMutation,
  useAdditionalExplanationTransferMutation,
  useAgreementTreasuryVerifyMutation,
  useCopyrightTreasuryVerifyMutation,

  // show full form
  useShowAgreementFullFormQuery,
  useShowCopyrightFullFormQuery,
  // end full form

  /// correction letter
  useShowCorrectionLetterAgreementDetailsQuery,
  useShowCorrectionLetterCopyrightDetailsQuery,
  useCorrectionLetterApprovalMutation,
  useGetCorrectionSentAgreementQuery,
  useGetCorrectionSentCopyrightQuery,
  /// end correction letter

  /// hearing
  useShowHearingAgreementDetailsQuery,
  useShowHearingCopyrightDetailsQuery,
  useHearingApprovalMutation,
  useGetHearingSentAgreementQuery,
  useGetHearingSentCopyrightQuery,
  useDeleteComplainantMutation,
  useHearingScheduleSentMutation,
  useShowHearingLetterQuery,
  /// end hearing

  /// recorded
  useShowRecordedAgreementDetailsQuery,
  useShowRecordedCopyrightDetailsQuery,
  useRecordedApprovalMutation,
  /// end recorded

  // unauthorized
  useShowUnauthorizedAgreementDetailsQuery,
  useShowUnauthorizedCopyrightDetailsQuery,
  useUnauthorizedApprovalMutation,
  // end unauthorized
  // register book
  useGetRegBookCopyrightQuery,
  useGetRegBookAgreementQuery,
  // certificate list
  useGetCertificateListQuery,
  // endorse list
  useGetEndorseListQuery,
  // agreement categories
  useGetAgreementCategoriesQuery,
  // copyright categories
  useGetCopyrightCategoriesQuery,
  // attention messages
  useGetAttentionMessagesQuery,
  // prepare certificate
  usePrepareCertificateMutation,
  // prepare endorse
  usePrepareEndorseMutation,
  // certificate edit permission
  useCertificateEditPermissionMutation,
  // show certificate
  useShowCertificateQuery,
  // show endorse
  useShowEndorseQuery,
  // certificate type update
  useCertificateTypeUpdateMutation,
  // certificate language update
  useCertificateLangUpdateMutation,
  // certificate attention update
  useCertificateAttentionUpdateMutation,
  // certificate artist work update
  useCertificateArtistWorkUpdateMutation,
  // certificate issue endorse
  useCertificateIssueEndorseMutation,
  // delete certificate language
  useDeleteCertificateLanguageMutation,
} = submittedCopyrightAgreementApiSlice;
