import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  ArrowDropDownCircle,
  Logout,
  Settings,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../../redux/api/authApiSlice";
import { logout } from "../../../redux/features/auth/authSlice";
import toast from "react-hot-toast";
import { apiSlice } from "../../../redux/api/apiSlice";

const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // logout event
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall, { isSuccess, isLoading, data }] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
    }
  }, [isSuccess]);

  return (
    <>
      <Box>
        <Box
          sx={{
            cursor: "pointer",
            color: "grey.900",
          }}
          display={"flex"}
          alignItems={"center"}
          border={"1px solid #808080"}
          px={"5px"}
          borderRadius={"50px"}
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Tooltip title="একাউন্ট সেটিংস">
            <ArrowDropDownCircle sx={{ color: "#808080" }} />
            <IconButton sx={{ ml: 2 }} size="small">
              <Avatar sx={{ width: 32, height: 32 }}></Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="/admin/profile">
          <MenuItem onClick={handleClose}>
            <Avatar /> প্রোফাইল
          </MenuItem>
        </Link>

        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            logoutHandler();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          লগ আউট
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
