import {
  APPLICANT_APPLICATIONS_DELETE,
  APPLICATION_EDIT_FIRST_PART_SHOW,
  APPLICATION_EDIT_FIRST_PART_UPDATE,
  APPLICATION_EDIT_PAYMENT_SHOW,
  APPLICATION_EDIT_SECOND_PART_SHOW,
  APPLICATION_EDIT_SECOND_PART_UPDATE,
  APPLICATION_EDIT_TRESARY_PAYMENT_UPDATE,
  DELETE_AUTHORIZE_TYPE,
  DELETE_BASIC_PUBLISH,
  DELETE_BASIC_WORK_TYPE,
  DELETE_EXISTS_FILES,
  DELETE_OWNER_TYPE,
  DELETE_POWER_TYPE,
  DELETE_PROFILE_DETAIL_SELF_TYPE,
  DELETE_PROFILE_DETAIL_TYPE,
  DELETE_PUBLISH_DETAILS,
  DELETE_WORK_SOFT_COPY,
  DELETE_WORK_TYPE,
  DELETE_WRITER_DETAILS,
} from "../constants";
import { apiSlice } from "./apiSlice";

const editFormApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // copyright first part
    showCopyrightFirstPart: builder.query({
      query: ({ id, trackReg }) =>
        APPLICATION_EDIT_FIRST_PART_SHOW + "/" + id + "?trackReg=" + trackReg,
      providesTags: ["edit_copyright_first_part"],
    }),
    updateCopyrightFirstPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_EDIT_FIRST_PART_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["edit_copyright_first_part"],
    }),
    deleteCopyrightFirstPartTeamTypes: builder.mutation({
      query: ({ id, applicant }) => ({
        url: APPLICANT_APPLICATIONS_DELETE + "/" + id,
        method: "DELETE",
        body: { applicant },
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // copyright second part
    showCopyrightSecondPart: builder.query({
      query: ({ id, trackReg }) =>
        APPLICATION_EDIT_SECOND_PART_SHOW + "/" + id + "?trackReg=" + trackReg,
      providesTags: ["edit_copyright_second_part"],
    }),
    updateCopyrightSecondPart: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_EDIT_SECOND_PART_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["edit_copyright_second_part"],
    }),

    // Delete profile details type
    deleteProfileDetailsType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: `${DELETE_PROFILE_DETAIL_TYPE}/${id}`,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // delete profile details self type
    deleteProfileDetailsSelfType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_PROFILE_DETAIL_SELF_TYPE + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_EXISTS_FILES
    deleteExistsFiles: builder.mutation({
      query: (fromData) => ({
        url: DELETE_EXISTS_FILES,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // delete/basic-work-type
    deleteBasicWorkType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: `${DELETE_BASIC_WORK_TYPE}/${id}`,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_WORK_TYPE
    deleteWorkType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_WORK_TYPE + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_BASIC_PUBLISH
    deleteBasicPublish: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_BASIC_PUBLISH + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_AUTHORIZE_TYPE
    deleteAuthorizeType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_AUTHORIZE_TYPE + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_WRITER_DETAILS
    deleteWriterDetails: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_WRITER_DETAILS + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_PUBLISH_DETAILS
    deletePublishDetails: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_PUBLISH_DETAILS + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_OWNER_TYPE
    deleteOwnerType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_OWNER_TYPE + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),
    // DELETE_WORK_SOFT_COPY
    deleteWorkSoftCopyType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_WORK_SOFT_COPY + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // DELETE_POWER_TYPE
    deletePowerType: builder.mutation({
      query: ({ id, fromData }) => ({
        url: DELETE_POWER_TYPE + "/" + id,
        method: "DELETE",
        body: fromData,
      }),
      invalidatesTags: [
        "edit_copyright_second_part",
        "applicant_edit_form_two",
        "show_correction_copyright_second_part",
      ],
    }),

    // APPLICATION_EDIT_PAYMENT_SHOW
    showPayment: builder.query({
      query: ({ id, trackReg }) =>
        APPLICATION_EDIT_PAYMENT_SHOW + "/" + id + "?trackReg=" + trackReg,
      providesTags: ["edit_treasury_payment"],
    }),

    // APPLICATION_EDIT_TRESARY_PAYMENT_UPDATE
    updateTresaryPayment: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_EDIT_TRESARY_PAYMENT_UPDATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["edit_treasury_payment"],
    }),
  }),
});

export const {
  // copyright first part
  useShowCopyrightFirstPartQuery,
  useUpdateCopyrightFirstPartMutation,
  useDeleteCopyrightFirstPartTeamTypesMutation,
  // copyright second part
  useShowCopyrightSecondPartQuery,
  useUpdateCopyrightSecondPartMutation,

  // delete profile details type
  useDeleteProfileDetailsTypeMutation,
  // delete profile details self type
  useDeleteProfileDetailsSelfTypeMutation,
  // DELETE_EXISTS_FILES
  useDeleteExistsFilesMutation,
  // delete/basic-work-type
  useDeleteBasicWorkTypeMutation,
  // DELETE_WORK_TYPE
  useDeleteWorkTypeMutation,
  // DELETE_BASIC_PUBLISH
  useDeleteBasicPublishMutation,
  // DELETE_AUTHORIZE_TYPE
  useDeleteAuthorizeTypeMutation,
  // DELETE_WRITER_DETAILS
  useDeleteWriterDetailsMutation,
  // DELETE_PUBLISH_DETAILS
  useDeletePublishDetailsMutation,
  // DELETE_OWNER_TYPE
  useDeleteOwnerTypeMutation,
  // DELETE_WORK_SOFT_COPY
  useDeleteWorkSoftCopyTypeMutation,
  // DELETE_POWER_TYPE
  useDeletePowerTypeMutation,
  // APPLICATION_EDIT_PAYMENT_SHOW
  useShowPaymentQuery,
  // APPLICATION_EDIT_TRESARY_PAYMENT_UPDATE
  useUpdateTresaryPaymentMutation,
} = editFormApiSlice;
