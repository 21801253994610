import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import {
  useGetDistributesQuery,
  useUpdateDistributesMutation,
} from "../../../redux/api/websiteSettingsApiSlice";
import Loader from "../../../components/custom/Loader";
import { Box, Typography } from "@mui/material";
import toast from "react-hot-toast";
import IconBreadcrumbs from "../../../components/custom/IconBreadcrumbs";
import { Home, SettingsApplications } from "@mui/icons-material";

const Distributor = () => {
  const { data, isLoading } = useGetDistributesQuery();
  const [
    update,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      error: errorUpdate,
    },
  ] = useUpdateDistributesMutation();
  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (data?.data) {
      const selectedIds = data.data
        .filter((item) => item.is_selected)
        .map((item) => item.id);
      setIds(selectedIds);
    }
  }, [data]);

  const handleSwitchChange = (e) => {
    const value = Number(e.target.value);
    const checked = e.target.checked;
    const updatedIds = checked
      ? [...ids, value]
      : ids.filter((id) => id !== value);
    setIds(updatedIds);

    // Call the update mutation with the new state
    update({ user_ids: updatedIds });
  };

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
    // eslint-disabled-next-line
  }, [isErrorUpdate, isSuccessUpdate, errorUpdate, dataUpdate]);

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: SettingsApplications,
      title: "রেজিস্ট্রেশনের নিয়মাবলী",
      active: true,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <Typography fontWeight="bold" mb={2}>
          আবেদন পত্র বণ্টনকারী
        </Typography>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>নং</th>
              <th>নাম</th>
              <th>পদবী</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item, i) => (
              <tr key={item.id}>
                <td>{i + 1}</td>
                <td>{item.name}</td>
                <td>{item.role?.bn_name}</td>
                <td>
                  <Form.Check
                    type="switch"
                    id={`custom-switch${i}`}
                    value={item.id}
                    checked={ids.includes(item.id)}
                    onChange={handleSwitchChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Distributor;
