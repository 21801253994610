import * as Yup from "yup";

export const initialValues = {
  form_status: 1,
  // for প্রথম প্রকাশনার বছর
  year: "",
  month: "",
  day: "",
  // for publication
  pub_year: "",
  pub_month: "",
  pub_day: "",

  type: 1,
  profiledetailtypes: [
    {
      name: "",
      email: "",
      mobile: "",
      address: "",
      image: "",
      nid: "",
      license: "",
      tin: "",
      memorandum: "",
      inst_struct: "",
      nationality: "বাংলাদেশী",
      companyName: "",
      companyAddress: "",
      designation: "",
      pRegno: "",
    },
  ],

  // step 3
  rightTypes: 13,
  selfType: 1,
  selfOther: "",
  selfLegacy: "",
  selftypes: [{ relatedright_id: 13, noc: "" }],

  category_id: "",
  subCategory: "",
  otherSubCategory: "",
  workDescription: "",
  workSoftCopies: [{ workSoftCopy: "" }],
  music_film: "",

  basicWorktype: 1,
  basicworktypes: [
    {
      keytype: "",
      testkeytype: 0,
      title: "",
      language: "",
      worktypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          deadDate: "",
        },
      ],
      basicpublishes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
        },
      ],
      authorize: 2,
      authorizetypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          approval_sc: "",
        },
      ],
    },
    {
      keytype: "",
      testkeytype: 1,
      title: "",
      language: "",
      worktypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          deadDate: "",
        },
      ],
      basicpublishes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
        },
      ],
      authorize: 2,
      authorizetypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          approval_sc: "",
        },
      ],
    },
    {
      keytype: "",
      testkeytype: 2,
      title: "",
      language: "",
      worktypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          deadDate: "",
        },
      ],
      basicpublishes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
        },
      ],
      authorize: 2,
      authorizetypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          approval_sc: "",
        },
      ],
    },
    {
      keytype: "",
      testkeytype: 3,
      title: "",
      language: "",
      worktypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          deadDate: "",
        },
      ],
      basicpublishes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
        },
      ],
      authorize: 2,
      authorizetypes: [
        {
          name: "",
          address: "",
          nationality: "বাংলাদেশী",
          approval_sc: "",
        },
      ],
    },
  ],

  // step 5 6 7
  workTitle: "",
  workLanguage: "",
  writerdetails: [
    {
      writerName: "",
      writerAddress: "",
      writerNationality: "বাংলাদেশী",
      writerDeadDate: "",
    },
  ],

  // step 8 9
  workPublishtype: 2,
  publishyear: "",
  publishplace: "",
  publishname: "",
  publishaddress: "",
  publishnationality: "বাংলাদেশী",
  literature_doc: "",

  // step 10
  otherPublishtype: 2,
  publishdetails: [
    {
      year: "",
      place: "",
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
      literature_more_doc: "",
    },
  ],

  // step 11
  ownerType: 2,
  ownertypes: [
    {
      ownerName: "",
      ownerAddress: "",
      ownerNationality: "বাংলাদেশী",
      partTitle: "",
    },
  ],

  // step 12
  powerType: 2,
  powertypes: [
    {
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
      license_pay: "",
    },
  ],
  // STEP 13
  industryactions: [
    {
      place: "",
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
      endYear: "",
    },
  ],

  // step 14
  comment: "",
  certificate: 1,
  work_title: "",
  certificatelanguages: [
    {
      author_creator: "",
      owner_right: "",
      address: "",
    },
  ],
  pub_unpub: 2,
  pub_unpub_year: "",
};

// Custom validation for file size

const fileSizeValidation = Yup.mixed().test(
  "fileSize",
  "ফাইলের আকার সর্বাধিক 2MB হতে হবে",
  (value) => {
    return !value || (value && value.size <= 2 * 1024 * 1024);
  }
);

export const validationSchema = ({
  otherSubCategoryData = [],
  checkBoxKeyType = [],
} = {}) => {
  return Yup.object({
    year: Yup.string().when("$workPublishtype", ([workPublishtype], schema) => {
      return workPublishtype === 1
        ? schema.required("বছর প্রযোজ্য")
        : schema.nullable();
    }),
    month: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 1
          ? schema.when("$day", ([day], schema) => {
              return day
                ? schema.required("মাস প্রযোজ্য")
                : schema.nullable();
            })
          : schema.nullable();
      }
    ),

    pub_year: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 2
          ? schema.required("বছর প্রযোজ্য")
          : schema.nullable();
      }
    ),
    pub_month: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 2
          ? schema.when("$pub_day", ([pub_day], schema) => {
              return pub_day
                ? schema.required("মাস প্রযোজ্য")
                : schema.nullable();
            })
          : schema.nullable();
      }
    ),

    // step 2
    type: Yup.number().required("ধরন প্রযোজ্য"),
    profiledetailtypes: Yup.array().of(
      Yup.object({
        name: Yup.string().required("নাম প্রযোজ্য"),
        email: Yup.string()
          .email("ইমেইল ঠিকানা সঠিক নয়")
          .required("ইমেইল প্রযোজ্য"),
        mobile: Yup.string().required("মোবাইল নম্বর প্রযোজ্য"),
        address: Yup.string().required("ঠিকানা প্রযোজ্য"),
        nationality: Yup.string().when("$type", ([type], schema) => {
          return type !== 3
            ? schema.required("জাতীয়তা প্রযোজ্য")
            : schema.nullable();
        }),
        nid: Yup.mixed()
          .required("পরিচয়পত্রের স্ক্যান কপি প্রযোজ্য")
          .test(
            "fileType",
            "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
            (value) =>
              value &&
              [
                "image/jpeg",
                "image/png",
                "image/jpg",
                "application/pdf",
              ].includes(value.type)
          ),
        image: Yup.mixed()
          .required("ছবি প্রযোজ্য")
          .test(
            "fileType",
            "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
            (value) =>
              value &&
              ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
          ),
        companyName: Yup.string().when("$type", ([type], schema) => {
          return type === 3
            ? schema.required("প্রতিষ্ঠানের নাম প্রযোজ্য")
            : schema.nullable();
        }),
        companyAddress: Yup.string().when("$type", ([type], schema) => {
          return type === 3
            ? schema.required("প্রতিষ্ঠানের ঠিকানা প্রযোজ্য")
            : schema.nullable();
        }),
        designation: Yup.string().when("$type", ([type], schema) => {
          return type === 3
            ? schema.required("পদবী প্রযোজ্য")
            : schema.nullable();
        }),
        pRegno: Yup.string().when("$type", ([type], schema) => {
          return type === 3
            ? schema.required("রেজিস্ট্রেশন নম্বর প্রযোজ্য")
            : schema.nullable();
        }),
        license: Yup.mixed().when("$type", ([type], schema) => {
          return type === 3
            ? schema
                .required("ট্রেড লাইসেন্স প্রযোজ্য")
                .test(
                  "fileType",
                  "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
                  (value) =>
                    value &&
                    [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "application/pdf",
                    ].includes(value.type)
                )
            : schema.nullable();
        }),
        tin: Yup.mixed().when("$type", ([type], schema) => {
          return type === 3
            ? schema
                .required("টিন প্রযোজ্য")
                .test(
                  "fileType",
                  "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
                  (value) =>
                    value &&
                    [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "application/pdf",
                    ].includes(value.type)
                )
            : schema.nullable();
        }),
        inst_struct: Yup.mixed().when("$type", ([type], schema) => {
          return type === 3
            ? schema
                .required("গঠনতন্ত্রের স্ক্যান কপি প্রযোজ্য")
                .test(
                  "fileType",
                  "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
                  (value) =>
                    value &&
                    [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "application/pdf",
                    ].includes(value.type)
                )
            : schema.nullable();
        }),
        memorandum: Yup.mixed().when("$type", ([type], schema) => {
          return type === 3
            ? schema
                .required("ফাইল প্রযোজ্য")
                .test(
                  "fileType",
                  "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
                  (value) =>
                    value &&
                    [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "application/pdf",
                    ].includes(value.type)
                )
            : schema.nullable();
        }),
      })
    ),

    // step 3
    rightTypes: Yup.string().required("নির্বাচন করুন"),
    selfType: Yup.number().required("নির্বাচন করুন"),
    selfOther: Yup.string()
      .nullable()
      .when("rightTypes", {
        is: (rightTypes) => ["6", "13", "19"].includes(rightTypes),
        then: () => Yup.string().required("অন্যান্য  প্রযোজ্য"),
        otherwise: () => Yup.string().nullable(),
      }),
    selfLegacy: Yup.string()
      .nullable()
      .when("rightTypes", {
        is: (rightTypes) => ["10", "16"].includes(rightTypes),
        then: () => Yup.string().required("ফাইল প্রযোজ্য"),
        otherwise: () => Yup.string().nullable(),
      }),
    selftypes: Yup.array().of(
      Yup.object().shape({
        noc: Yup.string().when("$rightTypes", {
          is: (rightTypes) => [8, 14].includes(rightTypes),
          then: () =>
            Yup.string().required("ফাইল প্রযোজ্য"),
          otherwise: () => Yup.string().nullable(),
        }),
      })
    ),

    // step 4
    category_id: Yup.number().required("বিভাগ প্রযোজ্য"),
    subCategory: Yup.number().required("উপ-বিভাগ প্রযোজ্য"),
    otherSubCategory: Yup.number().when("subCategory", {
      is: (subCategory) => otherSubCategoryData?.includes(subCategory),
      then: () => Yup.string().required("অন্যান্য প্রযোজ্য"),
      otherwise: () => Yup.string().nullable(),
    }),
    workDescription: Yup.string().required("কাজের বিবরণ প্রযোজ্য"),
    workSoftCopies: Yup.array().of(
      Yup.object({
        workSoftCopy: Yup.mixed()
          .required("ফাইল জমা দেওয়া প্রযোজ্য")
          .test(
            "fileType",
            "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
            (value) =>
              value &&
              [
                "image/jpeg",
                "image/png",
                "image/jpg",
                "application/pdf",
              ].includes(value.type)
          ),
      })
    ),
    music_film: Yup.mixed().when("$category_id", ([category_id], schema) => {
      return category_id === 2 || category_id === 7
        ? schema
            .required("কাজের ধরন প্রযোজ্য")
            .test(
              "fileType",
              "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
              (value) => value && ["application/pdf"].includes(value.type)
            )
        : schema.nullable();
    }),

    basicworktypes: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().when(["$basicWorktype", "testkeytype"], {
          is: (basicWorktype, testkeytype) =>
            basicWorktype === 2 && checkBoxKeyType.includes(testkeytype),
          then: () => Yup.string().required("শিরোনাম প্রযোজ্য"),
          otherwise: () => Yup.string().nullable(),
        }),
        language: Yup.string().when(["$basicWorktype", "testkeytype"], {
          is: (basicWorktype, testkeytype) =>
            basicWorktype === 2 && checkBoxKeyType.includes(testkeytype),
          then: () => Yup.string().required("ভাষা প্রযোজ্য"),
          otherwise: () => Yup.string().nullable(),
        }),
        worktypes: Yup.array().when(["$basicWorktype", "testkeytype"], {
          is: (basicWorktype, testkeytype) => {
            return basicWorktype === 2 && checkBoxKeyType.includes(testkeytype);
          },
          then: () =>
            Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required("নাম প্রযোজ্য"),
                address: Yup.string().required("ঠিকানা প্রযোজ্য"),
                nationality: Yup.string().required("জাতীয়তা প্রযোজ্য"),
                deadDate: Yup.string().nullable(),
              })
            ),
          otherwise: () =>
            Yup.array().of(
              Yup.object().shape({
                name: Yup.string().nullable(),
                address: Yup.string().nullable(),
                nationality: Yup.string().nullable(),
                deadDate: Yup.string().nullable(),
              })
            ),
        }),
        authorizetypes: Yup.array().when(
          ["$basicWorktype", "authorize", "testkeytype"],
          {
            is: (basicWorktype, authorize, testkeytype) => {
              return (
                basicWorktype === 2 &&
                checkBoxKeyType.includes(testkeytype) &&
                authorize === 1
              );
            },
            then: () =>
              Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required("নাম প্রযোজ্য"),
                  address: Yup.string().required("ঠিকানা প্রযোজ্য"),
                  nationality: Yup.string().required("জাতীয়তা প্রযোজ্য"),
                  approval_sc: fileSizeValidation.nullable(),
                })
              ),
            otherwise: () =>
              Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().nullable(),
                  address: Yup.string().nullable(),
                  nationality: Yup.string().nullable(),
                  approval_sc: fileSizeValidation.nullable(),
                })
              ),
          }
        ),
      })
    ),

    // step 5 6 7
    workTitle: Yup.string().required("শিরোনাম প্রযোজ্য"),
    workLanguage: Yup.string().required("ভাষা প্রযোজ্য"),
    writerdetails: Yup.array().of(
      Yup.object().shape({
        writerName: Yup.string().required("নাম প্রযোজ্য"),
        writerAddress: Yup.string().required("ঠিকানা প্রযোজ্য"),
        writerNationality: Yup.string().required("জাতীয়তা প্রযোজ্য"),
        writerDeadDate: Yup.date().nullable(),
      })
    ),

    // step 8 9
    workPublishtype: Yup.number("প্রকাশের ধরন প্রযোজ্য").required(
      "প্রকাশের ধরন প্রযোজ্য"
    ),
    publishplace: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 1
          ? schema.required("স্থান প্রযোজ্য")
          : schema.nullable();
      }
    ),
    publishname: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 1
          ? schema.required("নাম প্রযোজ্য")
          : schema.nullable();
      }
    ),
    publishaddress: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 1
          ? schema.required(" ঠিকানা প্রযোজ্য")
          : schema.nullable();
      }
    ),
    publishnationality: Yup.string().when(
      "$workPublishtype",
      ([workPublishtype], schema) => {
        return workPublishtype === 1
          ? schema.required("জাতীয়তা প্রযোজ্য")
          : schema.nullable();
      }
    ),

    // step 10
    otherPublishtype: Yup.number().required("ধরন প্রযোজ্য"),
    publishdetails: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().when(
          "$otherPublishtype",
          ([otherPublishtype], schema) => {
            return otherPublishtype === 1
              ? schema.required("বছর প্রযোজ্য")
              : schema.nullable();
          }
        ),
        place: Yup.string().when(
          "$otherPublishtype",
          ([otherPublishtype], schema) => {
            return otherPublishtype === 1
              ? schema.required("স্থান প্রযোজ্য")
              : schema.nullable();
          }
        ),
        name: Yup.string().when(
          "$otherPublishtype",
          ([otherPublishtype], schema) => {
            return otherPublishtype === 1
              ? schema.required("নাম প্রযোজ্য")
              : schema.nullable();
          }
        ),
        address: Yup.string().when(
          "$otherPublishtype",
          ([otherPublishtype], schema) => {
            return otherPublishtype === 1
              ? schema.required("ঠিকানা প্রযোজ্য")
              : schema.nullable();
          }
        ),
        nationality: Yup.string().when(
          "$otherPublishtype",
          ([otherPublishtype], schema) => {
            return otherPublishtype === 1
              ? schema.required("জাতীয়তা প্রযোজ্য")
              : schema.nullable();
          }
        ),
        literature_more_doc: Yup.mixed().nullable(),
      })
    ),

    // step 11

    ownerType: Yup.number().required("ধরন প্রযোজ্য"),
    ownertypes: Yup.array().of(
      Yup.object().shape({
        ownerName: Yup.string().when("$ownerType", ([ownerType], schema) => {
          return ownerType === 1
            ? schema.required("নাম প্রযোজ্য")
            : schema.nullable();
        }),
        ownerAddress: Yup.string().when("$ownerType", ([ownerType], schema) => {
          return ownerType === 1
            ? schema.required("ঠিকানা প্রযোজ্য")
            : schema.nullable();
        }),
        ownerNationality: Yup.string().when(
          "$ownerType",
          ([ownerType], schema) => {
            return ownerType === 1
              ? schema.required("জাতীয়তা প্রযোজ্য")
              : schema.nullable();
          }
        ),
        partTitle: Yup.string().when("$ownerType", ([ownerType], schema) => {
          return ownerType === 1
            ? schema.required("শিরোনাম প্রযোজ্য")
            : schema.nullable();
        }),
      })
    ),

    // step 12
    powerType: Yup.number().required("ধরন প্রযোজ্য"),
    powertypes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().when("$powerType", ([powerType], schema) => {
          return powerType === 1
            ? schema.required("নাম প্রযোজ্য")
            : schema.nullable();
        }),
        address: Yup.string().when("$powerType", ([powerType], schema) => {
          return powerType === 1
            ? schema.required("ঠিকানা প্রযোজ্য")
            : schema.nullable();
        }),
        nationality: Yup.string().when("$powerType", ([powerType], schema) => {
          return powerType === 1
            ? schema.required("জাতীয়তা প্রযোজ্য")
            : schema.nullable();
        }),
      })
    ),

    // step 13
    industryactions: Yup.array().of(
      Yup.object().shape({
        place: Yup.string().when("$category_id", ([category_id], schema) => {
          return category_id === 3
            ? schema.required("স্থান প্রযোজ্য")
            : schema.nullable();
        }),
        name: Yup.string().when("$category_id", ([category_id], schema) => {
          return category_id === 3
            ? schema.required("নাম প্রযোজ্য")
            : schema.nullable();
        }),
        address: Yup.string().when("$category_id", ([category_id], schema) => {
          return category_id === 3
            ? schema.required("ঠিকানা প্রযোজ্য")
            : schema.nullable();
        }),
        nationality: Yup.string().when(
          "$category_id",
          ([category_id], schema) => {
            return category_id === 3
              ? schema.required("জাতীয়তা প্রযোজ্য")
              : schema.nullable();
          }
        ),
        endYear: Yup.string().when("$subCategory", ([subCategory], schema) => {
          return subCategory === 53
            ? schema.required("শেষ বছর  প্রযোজ্য")
            : schema.nullable();
        }),
      })
    ),

    // // step 14
    certificate: Yup.number().required("সার্টিফিকেট প্রযোজ্য"),
    work_title: Yup.string().when("$certificate", ([certificate], schema) => {
      return certificate === 2
        ? schema.required("work title required")
        : schema.nullable();
    }),
    certificatelanguages: Yup.array().of(
      Yup.object().shape({
        author_creator: Yup.string().when(
          "$certificate",
          ([certificate], schema) => {
            return certificate === 2
              ? schema.required("authorrequired")
              : schema.nullable();
          }
        ),
        owner_right: Yup.string().when(
          "$certificate",
          ([certificate], schema) => {
            return certificate === 2
              ? schema.required("owship required")
              : schema.nullable();
          }
        ),
        address: Yup.string().when("$certificate", ([certificate], schema) => {
          return certificate === 2
            ? schema.required("address required")
            : schema.nullable();
        }),
      })
    ),
  });
};
