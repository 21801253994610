export const createFormDataAdd2 = (values) => {
  const formData = new FormData();

  const appendIfExists = (key, value) => {
    if (value !== undefined && value !== null && value !== "") {
      formData.append(key, value);
    }
  };

  // Append each field manually
  appendIfExists("year", values.year);
  appendIfExists("month", values.month);
  appendIfExists("day", values.day);
  appendIfExists("pub_year", values.pub_year);
  appendIfExists("pub_month", values.pub_month);
  appendIfExists("pub_day", values.pub_day);
  appendIfExists("type", values.type);
  appendIfExists("id", values.id);
  appendIfExists("trackReg", values.trackReg);

  appendIfExists("application_id", values.application_id);
  appendIfExists("submit", values.submit);

  // Append profiledetailtypes array
  values.profiledetailtypes.forEach((profile, index) => {
    Object.keys(profile).forEach((key) => {
      if (key === "photo") {
        formData.append(`profiledetailtypes[${index}][photo]`, profile[key]);
      } else {
        appendIfExists(`profiledetailtypes[${index}][${key}]`, profile[key]);
      }
    });
  });

  appendIfExists("rightTypes", values.rightTypes);
  appendIfExists("selfType", values.selfType);
  appendIfExists("selfOther", values.selfOther);
  appendIfExists("selfLegacy", values.selfLegacy);

  // Append selftypes array
  values.selftypes.forEach((selftype, index) => {
    Object.keys(selftype).forEach((key) => {
      if (key === "sphoto") {
        formData.append(`selftypes[${index}][sphoto]`, selftype[key]);
      } else {
        appendIfExists(`selftypes[${index}][${key}]`, selftype[key]);
      }
    });
  });

  appendIfExists("category_id", values.category_id);
  appendIfExists("subCategory", values.subCategory);
  appendIfExists("otherSubCategory", values.otherSubCategory);
  appendIfExists("workDescription", values.workDescription);

  // Append workSoftCopies array
  values.workSoftCopies.forEach((workSoftCopy, index) => {
    appendIfExists(
      `workSoftCopies[${index}][workSoftCopy]`,
      workSoftCopy.workSoftCopy
    );
    appendIfExists(
      `workSoftCopies[${index}][nameSoftCopy]`,
      workSoftCopy.nameSoftCopy
    );
  });

  appendIfExists("music_film", values.music_film);
  appendIfExists("basicWorktype", values.basicWorktype);

  // Append basicworktypes array
  values.basicworktypes.forEach((basicworktype, index) => {
    if (basicworktype.title) {
      Object.keys(basicworktype).forEach((key) => {
        if (Array.isArray(basicworktype[key])) {
          basicworktype[key].forEach((nestedItem, nestedIndex) => {
            Object.keys(nestedItem).forEach((nestedKey) => {
              formData.append(
                `basicworktypes[${index}][${key}][${nestedIndex}][${nestedKey}]`,
                nestedItem[nestedKey] === 0
                  ? `${nestedItem[nestedKey]}`
                  : nestedItem[nestedKey]
              );
            });
          });
        } else {
          formData.append(
            `basicworktypes[${index}][${key}]`,
            basicworktype[key]
          );
        }
      });
    }
  });

  appendIfExists("workTitle", values.workTitle);
  appendIfExists("workLanguage", values.workLanguage);

  // Append writerdetails array
  values.writerdetails.forEach((writerdetail, index) => {
    Object.keys(writerdetail).forEach((key) => {
      appendIfExists(`writerdetails[${index}][${key}]`, writerdetail[key]);
    });
  });

  appendIfExists("workPublishtype", values.workPublishtype);
  appendIfExists("publishyear", values.publishyear);
  appendIfExists("publishplace", values.publishplace);
  appendIfExists("publishname", values.publishname);
  appendIfExists("publishaddress", values.publishaddress);
  appendIfExists("publishnationality", values.publishnationality);
  appendIfExists("literature_doc", values.literature_doc);

  appendIfExists("otherPublishtype", values.otherPublishtype);

  // Append publishdetails array
  values.publishdetails.forEach((publishdetail, index) => {
    Object.keys(publishdetail).forEach((key) => {
      appendIfExists(`publishdetails[${index}][${key}]`, publishdetail[key]);
    });
  });

  appendIfExists("ownerType", values.ownerType);

  // Append ownertypes array
  values.ownertypes.forEach((ownertype, index) => {
    Object.keys(ownertype).forEach((key) => {
      appendIfExists(`ownertypes[${index}][${key}]`, ownertype[key]);
    });
  });

  appendIfExists("powerType", values.powerType);

  // Append powertypes array
  values.powertypes.forEach((powertype, index) => {
    Object.keys(powertype).forEach((key) => {
      appendIfExists(`powertypes[${index}][${key}]`, powertype[key]);
    });
  });

  // Append industryactions array
  values.industryactions.forEach((industryaction, index) => {
    Object.keys(industryaction).forEach((key) => {
      appendIfExists(`industryactions[${index}][${key}]`, industryaction[key]);
    });
  });

  appendIfExists("comment", values.comment);
  appendIfExists("certificate", values.certificate);
  appendIfExists("work_title", values.work_title);

  // Append certificatelanguages array
  values.certificatelanguages.forEach((certificatelanguage, index) => {
    Object.keys(certificatelanguage).forEach((key) => {
      appendIfExists(
        `certificatelanguages[${index}][${key}]`,
        certificatelanguage[key]
      );
    });
  });

  appendIfExists("pub_unpub", values.pub_unpub);
  appendIfExists("pub_unpub_year", values.pub_unpub_year);
  appendIfExists("form_status", values.form_status);

  return formData;
};
