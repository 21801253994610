import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGetAuthUserQuery } from "../redux/api/authApiSlice";
import Loader from "../components/custom/Loader";

const ProtectedRouteAuthorize = ({ requiredRoles, requiredPermissions }) => {
  const { data: user, isLoading, isError, isSuccess } = useGetAuthUserQuery();

  if (isLoading || !isSuccess) return <Loader />;
  
  if (isError) return <Navigate to="/" replace />;

  const userRole = user?.data?.role?.id;
  const userPermissions =
    user?.data?.role?.permissions.map((permission) => permission.group) || [];

  const userHasRequiredRole = requiredRoles
    ? requiredRoles.includes(userRole)
    : true;
  const userHasRequiredPermissions = requiredPermissions
    ? requiredPermissions.every((permission) =>
        userPermissions.includes(permission)
      )
    : true;

  if (!userHasRequiredRole || !userHasRequiredPermissions) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedRouteAuthorize;

/* <Route
  element={<ProtectedRoute allowedRoles={['Admin']} requiredPermissions={['VIEW_DASHBOARD']} />}
>
  <Route path="admin/dashboard" element={<Dashboard />} />
</Route>

<Route
  element={<ProtectedRoute allowedRoles={['Admin']} requiredPermissions={['MANAGE_USERS']} />}
>
  <Route path="admin/users" element={<Users />} />
</Route> */
