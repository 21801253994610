// App.js
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Frontend page elements
import FrontendLayout from "./layouts/frontend/FrontendLayout";
import Home from "./pages/frontend/Home";
import UserPanelLayout from "./layouts/frontend/UserPanelLayout";
import Profile from "./pages/frontend/userPanel/Profile";
import Applications from "./pages/frontend/userPanel/Applications";
import ApplicationForCopyright from "./pages/frontend/userPanel/form_one/ApplicationForCopyright";
import ContractCopy from "./pages/frontend/userPanel/agreement/ContractCopy";
import ForgotPassword from "./pages/frontend/ForgotPassword";
import ResetPassword from "./pages/frontend/ResetPassword";
import About from "./pages/frontend/About";
import Rules from "./pages/frontend/Rules";
import Documents from "./pages/frontend/Document";
import FAQ from "./pages/frontend/Faq";
import Contact from "./pages/frontend/Contact";
import Kormodetails from "./pages/frontend/Kormodetails";
import NotFoundPage from "./pages/404";
import EmailVerified from "./pages/EmailVerified";
import EmailVerify from "./pages/EmailVerify";

import { Buffer } from "buffer";
import AdminLayout from "./layouts/admin/AdminLayout";
import Dashboard from "./pages/admin/Dashboard";
import User from "./pages/admin/user/User";

import Add2 from "./pages/frontend/userPanel/form_two/Add2";
import PreviewApplicationFormOne from "./pages/frontend/userPanel/form_one/PreviewApplicationFormOne";
import Category from "./pages/admin/category/Category";
import Setting from "./pages/admin/Setting";
import PreviewApplicationFormTwo from "./pages/frontend/userPanel/form_two/PreviewApplicationFormTwo";
import FullFormPreview from "./pages/frontend/userPanel/full_form_preview/FullFormPreview";
import ContractCopyTwo from "./pages/frontend/userPanel/agreement/ContractCopyTwo";
import ContractCopyPreview from "./pages/frontend/userPanel/agreement/ContractCopyPreview";
import ContractTwoPreview from "./pages/frontend/userPanel/agreement/ContractTwoPreview";
import ContractFullFormPreview from "./pages/frontend/userPanel/agreement/fullContractPreview";
import ContractCopyTwoUpdate from "./pages/frontend/userPanel/agreement/ContractCopyTwoUpdate";
import CopyrightPayment from "./pages/frontend/userPanel/copyright_payment/CopyrightPayment";
import RegistrationSystems from "./pages/admin/registration_systems/RegistrationSystems";
import PaymentStatus from "./pages/frontend/userPanel/payment/PaymentStatus";
import PaymentResponse from "./pages/frontend/userPanel/payment/PaymentResponse";
import Distributor from "./pages/admin/distributor/Distributor";
import ApplicationsDistribution from "./pages/admin/applicationsAgreement/applications/ApplicationsDistribution";
import AgreementDistribution from "./pages/admin/applicationsAgreement/agreement/AgreementDistribution";
import ProtectedRouteAuthorize from "./utils/permissions";
import UnauthorizedPage from "./pages/401";
import ProtectedRouteDistribute from "./utils/ProtectedRoute";
import ProtectedAuthRoutes from "./utils/ProtectedAuthRoutes";
import AgreementDetailsSubmitted from "./pages/admin/submitted_application/details/AgreementDetailsSubmitted";
import CopyrightDetailsSubmitted from "./pages/admin/submitted_application/details/CopyrightDetailsSubmitted";
import CorrectionFormCopyright from "./pages/admin/submitted_application/correction-form/CorrectionForm_copyright/CorrectionFormCopyright";
import CorrectionFormAgreement from "./pages/admin/submitted_application/correction-form/CorrectionForm_agreement/CorrectionFormAgreement";
import IndexerCopyrightDetails from "./pages/admin/submitted_application/indexer/IndexerCopyrightDetails";
import IndexerAgreementDetails from "./pages/admin/submitted_application/indexer/IndexerAgreementDetails";
import SecondStepIndexerAgreementDetails from "./pages/admin/submitted_application/indexer/second-step/SecondStepIndexerAgreementDetails";
import SecondStepIndexerCopyrightDetails from "./pages/admin/submitted_application/indexer/second-step/SecondStepIndexerCopyrightDetails";
import AssistantExaminerCopyrightDetails from "./pages/admin/submitted_application/assistant-examiner/AssistantExaminerCopyrightDetails";
import AssistantExaminerAgreementDetails from "./pages/admin/submitted_application/assistant-examiner/AssistantExaminerAgreementDetails";
import RecentAgreementApplication from "./pages/admin/submitted_application/recent-applications/RecentAgreementApplication";
import RecentCopyrightApplication from "./pages/admin/submitted_application/recent-applications/RecentCopyrightApplication";
import ExplanationAgreement from "./pages/admin/submitted_application/explanation-applications/ExplanationAgreement";
import ExplanationCopyright from "./pages/admin/submitted_application/explanation-applications/ExplanationCopyright";
import ExplanationCopyrightDetails from "./pages/admin/submitted_application/explanation-applications/ExplanationDetails/ExplanationCopyrightDetails";
import ExplanationAgreementDetails from "./pages/admin/submitted_application/explanation-applications/ExplanationDetails/ExplanationAgreementDetails";
import ReplyExplanationCopyright from "./pages/admin/submitted_application/explanation-applications/ReplyExplanation/ReplyExplanationCopyright";
import ReplyExplanationAgreement from "./pages/admin/submitted_application/explanation-applications/ReplyExplanation/ReplyExplanationAgreement";
import AmendmentCopyrightApplication from "./pages/admin/submitted_application/amendment-applications/AmendmentCopyrightApplication";
import AmendmentAgreementApplication from "./pages/admin/submitted_application/amendment-applications/AmendmentAgreementApplication";
import LetterCorrectionCopyright from "./pages/admin/submitted_application/letter-correction/LetterCorrectionCopyright";
import LetterCorrectionAgreement from "./pages/admin/submitted_application/letter-correction/LetterCorrectionAgreement";
import LetterCorrectionAgreementDetails from "./pages/admin/submitted_application/letter-correction/letter-correction-details/LetterCorrectionAgreementDetails";
import LetterCorrectionCopyrightDetails from "./pages/admin/submitted_application/letter-correction/letter-correction-details/LetterCorrectionCopyrightDetails";
import LetterCorrectionApprovedCopyright from "./pages/admin/submitted_application/letter-correction-approved/LetterCorrectionApprovedCopyright";
import LetterCorrectionApprovedAgreement from "./pages/admin/submitted_application/letter-correction-approved/LetterCorrectionApprovedAgreement";
import AdminProfile from "./pages/admin/AdminProfile";
import LetterCorrectionSentCopyright from "./pages/admin/submitted_application/letter-correction-sent/LetterCorrectionSentCopyright";
import LetterCorrectionSentAgreement from "./pages/admin/submitted_application/letter-correction-sent/LetterCorrectionSentAgreement";
import HearingCopyright from "./pages/admin/submitted_application/hearing/HearingCopyright";
import HearingAgreement from "./pages/admin/submitted_application/hearing/HearingAgreement";
import HearingCopyrightDetails from "./pages/admin/submitted_application/hearing/hearing-details/HearingCopyrightDetails";
import HearingAgreementDetails from "./pages/admin/submitted_application/hearing/hearing-details/HearingAgreementDetails";
import HearingAgreementApplication from "./pages/admin/submitted_application/hearing-applications/HearingAgreementApplication";
import HearingCopyrightApplication from "./pages/admin/submitted_application/hearing-applications/HearingCopyrightApplication";
import HearingApprovedCopyright from "./pages/admin/submitted_application/hearing-approved/HearingApprovedCopyright";
import HearingApprovedAgreement from "./pages/admin/submitted_application/hearing-approved/HearingApprovedAgreement";
import HearingScheduleCopyright from "./pages/admin/submitted_application/hearing-schedule/HearingScheduleCopyright";
import HearingScheduleAgreement from "./pages/admin/submitted_application/hearing-schedule/HearingScheduleAgreement";
import HearingScheduleCopyrightDetails from "./pages/admin/submitted_application/hearing-schedule/hearing-schedule-details/HearingScheduleCopyrightDetails";
import HearingScheduleAgreementDetails from "./pages/admin/submitted_application/hearing-schedule/hearing-schedule-details/HearingScheduleAgreementDetails";
import FinalRevisedCopyright from "./pages/admin/submitted_application/final-revised/FinalRevisedCopyright";
import FinalRevisedAgreement from "./pages/admin/submitted_application/final-revised/FinalRevisedAgreement";
import InvestigationCopyright from "./pages/admin/submitted_application/investigation_sent/InvestigationCopyright";
import InvestigationAgreement from "./pages/admin/submitted_application/investigation_sent/InvestigationAgreement";
import InvestigationReportCopyright from "./pages/admin/submitted_application/investigation_report/InvestigationReportCopyright";
import InvestigationReportAgreement from "./pages/admin/submitted_application/investigation_report/InvestigationReportAgreement";
import RecordedCopyrightApplication from "./pages/admin/submitted_application/recorded/recorded-applications/RecordedCopyrightApplication";
import RecordedAgreementApplication from "./pages/admin/submitted_application/recorded/recorded-applications/RecordedAgreementApplication";
import RecordedCopyright from "./pages/admin/submitted_application/recorded/recorded/RecordedCopyright";
import RecordedAgreement from "./pages/admin/submitted_application/recorded/recorded/RecordedAgreement";
import RecordedCopyrightDetails from "./pages/admin/submitted_application/recorded/recorded/recorded-details/RecordedCopyrightDetails";
import RecordedAgreementDetails from "./pages/admin/submitted_application/recorded/recorded/recorded-details/RecordedAgreementDetails";
import RecordedApprovedCopyright from "./pages/admin/submitted_application/recorded/recorded-approved/RecordedApprovedCopyright";
import RecordedApprovedAgreement from "./pages/admin/submitted_application/recorded/recorded-approved/RecordedApprovedAgreement";
import UnauthorizedCopyright from "./pages/admin/submitted_application/unauthorized/unauthorized/UnauthorizedCopyright";
import UnauthorizedAgreement from "./pages/admin/submitted_application/unauthorized/unauthorized/UnauthorizedAgreement";
import UnauthorizedCopyrightDetails from "./pages/admin/submitted_application/unauthorized/unauthorized/unauthorized-details/UnauthorizedCopyrightDetails";
import UnauthorizedAgreementDetails from "./pages/admin/submitted_application/unauthorized/unauthorized/unauthorized-details/UnauthorizedAgreementDetails";
import UnauthorizedCopyrightApplication from "./pages/admin/submitted_application/unauthorized/unauthorized-applications/UnauthorizedCopyrightApplication";
import UnauthorizedAgreementApplication from "./pages/admin/submitted_application/unauthorized/unauthorized-applications/UnauthorizedAgreementApplication";
import UnauthorizedApprovedCopyright from "./pages/admin/submitted_application/unauthorized/unauthorized-approved/UnauthorizedApprovedCopyright";
import UnauthorizedApprovedAgreement from "./pages/admin/submitted_application/unauthorized/unauthorized-approved/UnauthorizedApprovedAgreement";
import { accessRoles } from "./utils/accessRoles";
import Branches from "./pages/admin/branches/Branches";
import FinalApprovedCopyright from "./pages/admin/submitted_application/final-approved/FinalApprovedCopyright";
import FinalApprovedAgreement from "./pages/admin/submitted_application/final-approved/FinalApprovedAgreement";
import FinalApprovedCopyrightDetails from "./pages/admin/submitted_application/final-approved/final-approved-details/FinalApprovedCopyrightDetails";
import FinalApprovedAgreementDetails from "./pages/admin/submitted_application/final-approved/final-approved-details/FinalApprovedAgreementDetails";
import UnauthorizedBookAgreement from "./pages/admin/submitted_application/book/unauthorized-register/UnauthorizedBookAgreement";
import UnauthorizedBookCopyright from "./pages/admin/submitted_application/book/unauthorized-register/UnauthorizedBookCopyright";
import RecordedBookCopyright from "./pages/admin/submitted_application/book/recorded_register/RecordedBookCopyright";
import RecordedBookAgreement from "./pages/admin/submitted_application/book/recorded_register/RecordedBookAgreement";
import RegisterBookCopyright from "./pages/admin/submitted_application/book/register_book/RegisterBookCopyright";
import RegisterBookAgreement from "./pages/admin/submitted_application/book/register_book/RegisterBookAgreement";
import IndexerBookCopyright from "./pages/admin/submitted_application/book/indexer-book/IndexerBookCopyright";
import IndexerBookAgreement from "./pages/admin/submitted_application/book/indexer-book/IndexerBookAgreement";
import CertificateCopyright from "./pages/admin/submitted_application/certificate-endorse/CertificateCopyright";
import EndorseAgreement from "./pages/admin/submitted_application/certificate-endorse/EndorseAgreement";
import PrintCopyrightCertificate from "./pages/admin/submitted_application/certificate-endorse/print-certificate/PrintCopyrightCertificate";
import PrintAgreementEndorse from "./pages/admin/submitted_application/certificate-endorse/print-certificate/PrintAgreementEndorse";
import ApplicationsTracking from "./pages/admin/ApplicationsTracking";
import AttentionMessagesAdmin from "./pages/admin/attention_messages/AttentionMessagesAdmin";
import CopyrightFirstPartEdit from "./pages/admin/form_edit/CopyrightFirstPartEdit";
import CopyrightSecondPartEdit from "./pages/admin/form_edit/CopyrightSecondPartEdit";
import FileActivity from "./pages/admin/report/file_activity/FileActivity";
import UserFileCount from "./pages/admin/report/user_file_count/UserFileCount";
import TreasuryPaymentEdit from "./pages/admin/form_edit/TreasuryPaymentEdit";
import ApplicantCopyrightSecondPartEdit from "./pages/frontend/userPanel/form_edit_copyright/ApplicantCopyrightSecondPartEdit";
import { CorrectionForm } from "./pages/frontend/userPanel/correction_form_updated/CorrectionForm";
import CorrectionCopyrightFirstPart from "./pages/frontend/userPanel/correction_form_updated/copyright/CorrectionCopyrightFirstPart";
import CorrectionCopyrightSecondPart from "./pages/frontend/userPanel/correction_form_updated/copyright/CorrectionCopyrightSecondPart";
import CorrectionPayment from "./pages/frontend/userPanel/correction_form_updated/CorrectionPayment";
import SuccessCorrection from "./components/custom/status/SuccessCorrection";

window.Buffer = Buffer;

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <Routes>
      {/* Frontend User Panel */}
      <Route path="/" element={<UserPanelLayout />}>
        <Route element={<ProtectedAuthRoutes />}>
          <Route path="user-profile" element={<Profile />} />
          <Route path="applicant" element={<Applications />} />
          {/* application form one  */}

          {/* agreement form  */}
          <Route path="/agreement-form-add" element={<ContractCopy />} />
          <Route
            path="/agreement-form-add/:id/preview"
            element={<ContractCopyPreview />}
          />

          <Route
            path="/agreement-form-add-two/:id"
            element={<ContractCopyTwo />}
          />
          <Route
            path="/agreement-form-add-two/:id/preview"
            element={<ContractTwoPreview />}
          />
          <Route
            path="/agreement-form-add-two/:contractId/:agreementId/edit"
            element={<ContractCopyTwoUpdate />}
          />

          <Route
            path="/contract-full-preview/:id"
            element={<ContractFullFormPreview />}
          />

          {/* copyright applications  */}
          <Route
            path="/applicant/applications"
            element={<ApplicationForCopyright />}
          />

          <Route
            path="/applicant/applications/:id/preview"
            element={<PreviewApplicationFormOne />}
          />
          <Route path="applicant/application-details/:id" element={<Add2 />} />

          <Route
            path="applicant/application-details/:profileId/:profileDetailsId/preview"
            element={<PreviewApplicationFormTwo />}
          />

          <Route
            path="applicant/application-full-preview/:profileId"
            element={<FullFormPreview />}
          />

          {/* 
            ======================
            correction application 
            ====================== 
          */}
          <Route path="correction/form/:id" element={<CorrectionForm />} />
          {/* correction copyright  */}
          <Route
            path="correction-copyright-first-part/:id"
            element={<CorrectionCopyrightFirstPart />}
          />
          <Route
            path="correction-copyright-second-part/:id"
            element={<CorrectionCopyrightSecondPart />}
          />

          <Route
            path="success-correction/:appType"
            element={<SuccessCorrection />}
          />

          {/* correction payment for copyright and agreement  */}
          <Route
            path="correction-payment/:id"
            element={<CorrectionPayment />}
          />

          {/* update copyright second part  */}
          <Route
            path="applicant/application-second-part-update/:id"
            element={<ApplicantCopyrightSecondPartEdit />}
          />

          {/* payment info  */}
          <Route
            path="applicant/application-payment/:profileId"
            element={<CopyrightPayment />}
          />
          <Route
            path="payment-response/:status"
            element={<PaymentResponse />}
          />
          <Route path="payment-status/:status" element={<PaymentStatus />} />
        </Route>
      </Route>

      {/* Frontend page elements */}
      <Route path="/" exact element={<FrontendLayout />}>
        <Route index element={<Home />} />
        <Route path="copyright" element={<About />} />
        <Route path="rules" element={<Rules />} />
        <Route path="rules/:slug" element={<Rules />} />
        <Route path="documents" element={<Documents />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="contact" element={<Contact />} />
        <Route path="details/:slug" element={<Kormodetails />} />
        {/* auth / */}
        <Route path="verification-email" element={<EmailVerified />} />
        <Route path="verify-email" element={<EmailVerify />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      {/* admin layout  */}
      <Route element={<ProtectedAuthRoutes />}>
        <Route path="/admin" element={<AdminLayout />}>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.CopyrightAdmin,
                  accessRoles.Register,
                  accessRoles.AssistantRegister,
                  accessRoles.DeputyRegister,
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                  accessRoles.Inspector,
                  accessRoles.Librarian,
                  accessRoles.Programmer,
                  accessRoles.Calligrapher,
                ]}
              />
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<AdminProfile />} />
          </Route>
          {/* super admin */}
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.CopyrightAdmin]}
              />
            }
          >
            <Route path="users" element={<User />} />
            <Route path="category" element={<Category />} />
            <Route path="settings" element={<Setting />} />
            <Route path="branches" element={<Branches />} />
            <Route
              path="distributor-application-form"
              element={<Distributor />}
            />
            <Route
              path="registration-systems"
              element={<RegistrationSystems />}
            />
          </Route>
          {/* all admin  */}
          <Route element={<ProtectedRouteDistribute />}>
            <Route
              path="distribution/applications"
              element={<ApplicationsDistribution />}
            />
            <Route
              path="distribution/agreement"
              element={<AgreementDistribution />}
            />
          </Route>
          {/* poridorshok */}
          {/* submitted  */}{" "}
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Register,
                  accessRoles.AssistantRegister,
                  accessRoles.DeputyRegister,
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                  accessRoles.Inspector,
                  accessRoles.Librarian,
                  accessRoles.Programmer,
                  accessRoles.Calligrapher,
                ]}
              />
            }
          >
            <Route
              path="submitted-copyright"
              element={<RecentCopyrightApplication />}
            />
            <Route
              path="submitted-agreement"
              element={<RecentAgreementApplication />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.AssistantRegister,
                  accessRoles.DeputyRegister,
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                  accessRoles.Inspector,
                  accessRoles.Librarian,
                  accessRoles.Programmer,
                  accessRoles.Calligrapher,
                ]}
              />
            }
          >
            {/* correction-form  */}
            <Route
              path="correction-form-copyright"
              element={<CorrectionFormCopyright />}
            />
            <Route
              path="correction-form-agreement"
              element={<CorrectionFormAgreement />}
            />
            {/* explanation  */}
            <Route
              path="explanation-list-copyright"
              element={<ExplanationCopyright />}
            />
            <Route
              path="explanation-list-agreement"
              element={<ExplanationAgreement />}
            />
            {/* তদন্তের নথি */}
            <Route
              path="investigation-copyright"
              element={<InvestigationCopyright />}
            />
            <Route
              path="investigation-agreement"
              element={<InvestigationAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Register,
                  accessRoles.AssistantRegister,
                  accessRoles.DeputyRegister,
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                  accessRoles.Librarian,
                  accessRoles.Programmer,
                  accessRoles.Calligrapher,
                ]}
              />
            }
          >
            {/* অতিরিক্ত ব্যাখ্যার জবাব  */}
            <Route
              path="reply-to-additional-explanation-copyright"
              element={<ReplyExplanationCopyright />}
            />
            <Route
              path="reply-to-additional-explanation-agreement"
              element={<ReplyExplanationAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                ]}
              />
            }
          >
            {/* সংশোধনের চিঠি  */}
            <Route
              path="letter-of-correction-copyright"
              element={<LetterCorrectionCopyright />}
            />
            <Route
              path="letter-of-correction-agreement"
              element={<LetterCorrectionAgreement />}
            />
            {/* সংশোধনের চিঠি (অনুমোদিত) */}
            <Route
              path="letter-of-correction-approved-copyright"
              element={<LetterCorrectionApprovedCopyright />}
            />
            <Route
              path="letter-of-correction-approved-agreement"
              element={<LetterCorrectionApprovedAgreement />}
            />
            {/* চূড়ান্ত ভাবে সংশোধিত নথি  */}
            <Route
              path="final-revised-document-copyright"
              element={<FinalRevisedCopyright />}
            />
            <Route
              path="final-revised-document-agreement"
              element={<FinalRevisedAgreement />}
            />
            {/* শুনানীর চিঠি  */}
            <Route path="hearing-copyright" element={<HearingCopyright />} />
            <Route path="hearing-agreement" element={<HearingAgreement />} />

            {/* শুনানীর চিঠি (অনুমোদিত) */}
            <Route
              path="hearing-approved-copyright"
              element={<HearingApprovedCopyright />}
            />
            <Route
              path="hearing-approved-agreement"
              element={<HearingApprovedAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Register,
                  accessRoles.DeputyRegister,
                  accessRoles.Programmer,
                  accessRoles.Examiner,
                ]}
              />
            }
          >
            {/* সংশোধনের নথি  */}
            <Route
              path="amendment-application-copyright"
              element={<AmendmentCopyrightApplication />}
            />
            <Route
              path="amendment-application-agreement"
              element={<AmendmentAgreementApplication />}
            />
            {/* শুনানীর নথি  */}
            <Route
              path="hearing-application-copyright"
              element={<HearingCopyrightApplication />}
            />
            <Route
              path="hearing-application-agreement"
              element={<HearingAgreementApplication />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Register,
                  accessRoles.DeputyRegister,
                  accessRoles.Examiner,
                  accessRoles.AssistantExaminer,
                  accessRoles.Indexer,
                ]}
              />
            }
          >
            {/* শুনানী গ্রহনের নথি */}
            <Route
              path="hearing-schedule-copyright"
              element={<HearingScheduleCopyright />}
            />
            <Route
              path="hearing-schedule-agreement"
              element={<HearingScheduleAgreement />}
            />
          </Route>
          {/* inspector  */}
          <Route
            path="submitted-copyright-details-inspector/:id"
            element={<CopyrightDetailsSubmitted />}
          />
          <Route
            path="submitted-agreement-details-inspector/:id"
            element={<AgreementDetailsSubmitted />}
          />
          {/* indexer  */}
          <Route
            path="submitted-copyright-details-indexer/:id"
            element={<IndexerCopyrightDetails />}
          />
          <Route
            path="submitted-agreement-details-indexer/:id"
            element={<IndexerAgreementDetails />}
          />
          <Route
            path="submitted-agreement-details-indexer-second-step/:id"
            element={<SecondStepIndexerAgreementDetails />}
          />
          <Route
            path="submitted-copyright-details-indexer-second-step/:id"
            element={<SecondStepIndexerCopyrightDetails />}
          />
          {/* AssistantExaminer  */}
          <Route
            path="submitted-copyright-details-verify/:id"
            element={<AssistantExaminerCopyrightDetails />}
          />
          <Route
            path="submitted-agreement-details-verify/:id"
            element={<AssistantExaminerAgreementDetails />}
          />
          {/* explanation details */}
          <Route
            path="explanation-copyright-details/:id"
            element={<ExplanationCopyrightDetails />}
          />
          <Route
            path="explanation-agreement-details/:id"
            element={<ExplanationAgreementDetails />}
          />
          {/* letter-of-correction start ========== */}
          <Route
            path="letter-of-correction-copyright-details/:id"
            element={<LetterCorrectionCopyrightDetails />}
          />
          <Route
            path="letter-of-correction-agreement-details/:id"
            element={<LetterCorrectionAgreementDetails />}
          />
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.CopyrightAdmin]}
              />
            }
          >
            {/* সংশোধনের জন্য প্রেরিত  */}
            <Route
              path="letter-of-correction-sent-copyright"
              element={<LetterCorrectionSentCopyright />}
            />
            <Route
              path="letter-of-correction-sent-agreement"
              element={<LetterCorrectionSentAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Register,
                  accessRoles.DeputyRegister,
                ]}
              />
            }
          >
            {/* তদন্তের রিপোর্ট */}
            <Route
              path="investigation-report-copyright"
              element={<InvestigationReportCopyright />}
            />
            <Route
              path="investigation-report-agreement"
              element={<InvestigationReportAgreement />}
            />

            {/* নথিজাত নথি */}
            <Route path="recorded-copyright" element={<RecordedCopyright />} />
            <Route path="recorded-agreement" element={<RecordedAgreement />} />

            {/* অননুমোদিত নথি */}
            <Route
              path="unauthorized-application-copyright"
              element={<UnauthorizedCopyrightApplication />}
            />
            <Route
              path="unauthorized-application-agreement"
              element={<UnauthorizedAgreementApplication />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize requiredRoles={[accessRoles.Indexer]} />
            }
          >
            {/* চূড়ান্ত নথিজাত নথি  */}
            <Route
              path="recorded-application-copyright"
              element={<RecordedCopyrightApplication />}
            />
            <Route
              path="recorded-application-agreement"
              element={<RecordedAgreementApplication />}
            />
            {/* নথিজাত অনুমোদিত */}
            <Route
              path="recorded-approved-copyright"
              element={<RecordedApprovedCopyright />}
            />
            <Route
              path="recorded-approved-agreement"
              element={<RecordedApprovedAgreement />}
            />
            {/* চূড়ান্তভাবে অননুমোদন  */}
            <Route
              path="unauthorized-copyright"
              element={<UnauthorizedCopyright />}
            />
            <Route
              path="unauthorized-agreement"
              element={<UnauthorizedAgreement />}
            />
            {/* অননুমোদন অননুমোদিত */}
            <Route
              path="unauthorized-approved-copyright"
              element={<UnauthorizedApprovedCopyright />}
            />
            <Route
              path="unauthorized-approved-agreement"
              element={<UnauthorizedApprovedAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.Indexer,
                  accessRoles.DeputyRegister,
                  accessRoles.Register,
                ]}
              />
            }
          >
            {/* recorded start ========== */}
            <Route
              path="recorded-copyright-details/:id"
              element={<RecordedCopyrightDetails />}
            />
            <Route
              path="recorded-agreement-details/:id"
              element={<RecordedAgreementDetails />}
            />
            {/* recorded end ========== */}
            {/* unauthorized start ========== */}
            <Route
              path="unauthorized-copyright-details/:id"
              element={<UnauthorizedCopyrightDetails />}
            />
            <Route
              path="unauthorized-agreement-details/:id"
              element={<UnauthorizedAgreementDetails />}
            />
            {/* unauthorized end ========== */}
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.Calligrapher]}
              />
            }
          >
            {/*চূড়ান্তভাবে অনুমোদিত নথি*/}
            <Route
              path="final-approved-copyright"
              element={<FinalApprovedCopyright />}
            />
            <Route
              path="final-approved-agreement"
              element={<FinalApprovedAgreement />}
            />
            <Route
              path="final-approved-copyright-certificate-form/:id"
              element={<FinalApprovedCopyrightDetails />}
            />
            <Route
              path="final-approved-agreement-certificate-form/:id"
              element={<FinalApprovedAgreementDetails />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.Indexer, accessRoles.Librarian]}
              />
            }
          >
            {/*অননুমোদিত রেজিস্টার বুক*/}
            <Route
              path="unauthorized-register-book-copyright"
              element={<UnauthorizedBookCopyright />}
            />
            <Route
              path="unauthorized-register-book-agreement"
              element={<UnauthorizedBookAgreement />}
            />
            {/*নথিজাত রেজিস্টার বুক*/}
            <Route
              path="recorded-register-book-copyright"
              element={<RecordedBookCopyright />}
            />
            <Route
              path="recorded-register-book-agreement"
              element={<RecordedBookAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.Librarian]}
              />
            }
          >
            {/*রেজিস্টার বুক*/}
            <Route
              path="register-book-copyright"
              element={<RegisterBookCopyright />}
            />
            <Route
              path="register-book-agreement"
              element={<RegisterBookAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize requiredRoles={[accessRoles.Indexer]} />
            }
          >
            {/*ইনডেক্স রেজিস্টার বুক*/}
            <Route
              path="indexer-register-book-copyright"
              element={<IndexerBookCopyright />}
            />
            <Route
              path="indexer-register-book-agreement"
              element={<IndexerBookAgreement />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.Register, accessRoles.Calligrapher]}
              />
            }
          >
            {/*সার্টিফিকেট & এন্ডোর্স */}
            <Route
              path="certificates-copyright"
              element={<CertificateCopyright />}
            />
            <Route path="endorse-agreement" element={<EndorseAgreement />} />
            <Route
              path="print-copyright-certificate/:id/:user_id"
              element={<PrintCopyrightCertificate />}
            />
            <Route
              path="print-agreement-endorse/:id/:user_id"
              element={<PrintAgreementEndorse />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.CopyrightAdmin,
                  accessRoles.Calligrapher,
                ]}
              />
            }
          >
            {/* এটেনশন বার্তা */}
            <Route
              path="attention-messages"
              element={<AttentionMessagesAdmin />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.CopyrightAdmin,
                  accessRoles.Register,
                  accessRoles.AssistantRegister,
                  accessRoles.AssistantExaminer,
                  accessRoles.Examiner,
                  accessRoles.DeputyRegister,
                  accessRoles.Indexer,
                  accessRoles.Inspector,
                  accessRoles.Programmer,
                ]}
              />
            }
          >
            {/* আবেদনের অবস্থান অনুসন্ধান */}
            <Route
              path="applications-tracking"
              element={<ApplicationsTracking />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[accessRoles.CopyrightAdmin]}
              />
            }
          >
            {/* admin form edit copyright */}
            <Route
              path="copyright-first-part-edit/:id/:trackReg"
              element={<CopyrightFirstPartEdit />}
            />
            <Route
              path="copyright-second-part-edit/:id/:trackReg"
              element={<CopyrightSecondPartEdit />}
            />
            <Route
              path="treasury-payment-edit/:id/:trackReg"
              element={<TreasuryPaymentEdit />}
            />
          </Route>
          <Route
            element={
              <ProtectedRouteAuthorize
                requiredRoles={[
                  accessRoles.CopyrightAdmin,
                  accessRoles.Register,
                  accessRoles.DeputyRegister,
                ]}
              />
            }
          >
            {/* report */}
            <Route path="file-activity-list" element={<FileActivity />} />
            <Route path="user-file-count" element={<UserFileCount />} />
          </Route>
          {/* hearing start ========== */}
          <Route
            path="hearing-copyright-details/:id"
            element={<HearingCopyrightDetails />}
          />
          <Route
            path="hearing-agreement-details/:id"
            element={<HearingAgreementDetails />}
          />
          {/* hearing end ========== */}
          <Route
            path="hearing-schedule-copyright-details/:id"
            element={<HearingScheduleCopyrightDetails />}
          />
          <Route
            path="hearing-schedule-agreement-details/:id"
            element={<HearingScheduleAgreementDetails />}
          />
        </Route>
      </Route>
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="page-not-found" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
