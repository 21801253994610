export const accessRoles = {
  CopyrightAdmin: 1,
  Register: 2,
  AssistantRegister: 3,
  DeputyRegister: 4,
  Examiner: 5,
  AssistantExaminer: 6,
  Indexer: 7,
  Inspector: 8,
  Librarian: 9,
  Applicant: 10,
  Programmer: 11,
  Calligrapher: 12,
};
