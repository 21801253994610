import React, { useEffect, useState } from "react";
import {
  useLazyGetActivityCountHistoryQuery,
  useLazyGetFileStatusGroupQuery,
} from "../../../../redux/api/reportApiSlice";
import { Button, Modal } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { localDateFormat } from "../../../../utils/dateFormat";
import { DateRange, FeaturedPlayListOutlined } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";

const ActivityHistoryCount = ({ show, onHide, data }) => {
  const [fetchHistory, { data: history }] =
    useLazyGetActivityCountHistoryQuery();
  const [fetchFileStatus, { data: fileStatus }] =
    useLazyGetFileStatusGroupQuery();

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  useEffect(() => {
    if (data?.id) {
      setIsLoadingHistory(true); // Set loading to true before fetch
      setIsLoadingStatus(true); // Set loading to true before fetch
      fetchHistory({ user_id: data.id }).finally(() =>
        setIsLoadingHistory(false)
      ); // Reset loading after fetch
      fetchFileStatus(data?.id).finally(() => setIsLoadingStatus(false)); // Reset loading after fetch
    }
  }, [data?.id]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <label>
          {data?.name} ( {data?.role?.bn_name} )
        </label>
      </Modal.Header>
      <Modal.Body>
        {isLoadingHistory || isLoadingStatus ? (
          <div className="text-center">
            <BeatLoader />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="mb-2">
                    <DateRange sx={{ fontSize: "18px" }} /> আজকের কার্যক্রম -{" "}
                    {localDateFormat(new Date())}
                  </label>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রেরিত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {history?.data?.total?.sent_files}
                      </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রাপ্ত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {history?.data?.total?.received_files}
                      </Badge>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
            <label className="mb-2">
              <FeaturedPlayListOutlined sx={{ fontSize: "18px" }} /> নথির
              অবস্থান ও সংখ্যা{" "}
            </label>
            <ListGroup as="ol">
              {fileStatus?.data?.length > 0 ? (
                fileStatus?.data?.map((item, i) => (
                  <ListGroup.Item
                    key={i}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{item?.statusName}</div>
                    </div>
                    <Badge bg="primary" pill>
                      {item?.total}
                    </Badge>
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <label>কোনো রেকর্ড পাওয়া যায়নি</label>
                </ListGroup.Item>
              )}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <Button size="sm" variant="secondary" onClick={onHide}>
            বন্ধ করুন
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityHistoryCount;
