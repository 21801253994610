import { apiSlice } from "./apiSlice";
import {
  ACTIVITY_COUNT_HISTORY,
  COUNT_BY_FILE_STATUS_GROUP,
  REPORT_FILE_ACTIVITY_LIST,
  USERS_BY_ROLE_WITH_FILE_COUNT,
} from "../constants";

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFileActivity: builder.query({
      query: ({ sendFrom = "", page = 1, sendTo = "", date = "" }) => {
        let url = `${REPORT_FILE_ACTIVITY_LIST}?page=${page}&per_page=10`;
        if (sendTo) {
          url += `&sendTo=${sendTo}`;
        }
        if (sendFrom) {
          url += `&sendFrom=${sendFrom}`;
        }
        if (date) {
          url += `&date=${date}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getUserFileCount: builder.query({
      query: ({ role_id = "", user_id = "", appType = "" }) => {
        let url = `${USERS_BY_ROLE_WITH_FILE_COUNT}?appType=${appType}`;
        if (user_id) {
          url += `&user_id=${user_id}`;
        }
        if (role_id) {
          url += `&role_id=${role_id}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getActivityCountHistory: builder.query({
      query: ({ date = "", user_id = "" }) => {
        let url = `${ACTIVITY_COUNT_HISTORY}?user_id=${user_id}`;

        if (date) {
          url += `&date=${date}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getFileStatusGroup: builder.query({
      query: (user_id) => {
        let url = `${COUNT_BY_FILE_STATUS_GROUP}?user_id=${user_id || ""}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetFileActivityQuery,
  useGetUserFileCountQuery,
  useGetActivityCountHistoryQuery,
  useGetFileStatusGroupQuery,

  useLazyGetActivityCountHistoryQuery,
  useLazyGetFileStatusGroupQuery,
  // other exported functions...
} = reportApiSlice;
