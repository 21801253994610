import React from "react";
import { useLocation, useParams } from "react-router-dom";
import LoadingOverlay from "../../../../components/custom/LoadingOverlay";
import {
  usePaymentStatusQuery,
  usePaymentResponseQuery,
} from "../../../../redux/api/paymentApiSlice";
import toast from "react-hot-toast";
import StatusModal from "./StatusModal";

const PaymentResponse = () => {
  const { status } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const transId = query.get("transId");

  const { data: dataRes, isLoading: isLoadingRes } = usePaymentResponseQuery({
    status,
    transId,
  });

  let transaction_id;

  if (dataRes?.data?.redirect_url) {
    try {
      const url = new URL(dataRes?.data?.redirect_url);
      const dataParam = url.searchParams.get("data");

      if (dataParam) {
        const decoded = atob(dataParam);
        const resData = JSON.parse(decoded);
        transaction_id = resData?.transaction_id;
      }
    } catch (error) {
      toast.error("Invalid", error);
    }
  }

  const { data } = usePaymentStatusQuery(transaction_id);
  const resStatus = data?.data?.status;

  // StatusModal;
  const conditon =
    resStatus === "Canceled" ||
    resStatus === "Complete" ||
    resStatus === "Failed";

  const url =
    Number(data?.data?.profile?.apptype) === 2
      ? `/contract-full-preview/${data?.data?.profile_id}`
      : `/applicant/application-full-preview/${data?.data?.profile_id}`;

  return conditon ? (
    <StatusModal status={resStatus} url={url} showModal={conditon} />
  ) : (
    <LoadingOverlay />
  );
};

export default PaymentResponse;
