import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants";
import { logout } from "../features/auth/authSlice";

const baseQuery = async (args, api, extraOptions) => {
  // Fetch CSRF token if needed
  // await fetchBaseQuery({
  //   baseUrl: BASE_URL,
  //   credentials: "include", // Ensure cookies are included
  // })("/sanctum/csrf-cookie", api, extraOptions);

  const result = await fetchBaseQuery({
    baseUrl: BASE_URL,
    // credentials: "include", // Include credentials for cross-origin requests
    prepareHeaders: (headers, { getState }) => {
      // Get the token from Redux state
      const token = getState().auth?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  })(args, api, extraOptions);

  if (result?.error && result.error?.status === 419) {
    api.dispatch(logout());
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "User",
    "categories",
    "AgreementFormOne",
    "AgreementFormTwo",
    "categories_catalog",
    "copyright",
    "relatedRight",
    "registration_systems",
    "applicationsCopyright",
    "applicationsAgreement",
    "submittedAgreement",
    "submittedCopyright",
    "admin_attention_messages",
    "edit_copyright_first_part",
    "edit_copyright_second_part",
    "edit_treasury_payment",
    "applicant_edit_form_two",
    "show_correction",
    "show_correction_copyright_first_part",
    "show_correction_copyright_second_part",
    "show_correction_payment",
  ],
  endpoints: () => ({}),
});

// const baseQuery = fetchBaseQuery({
//   baseUrl: BASE_URL,
//   prepareHeaders: (headers, { getState }) => {
//     // Get the token from Redux state
//     const token = getState().auth?.token;
//     if (token) {
//       headers.set("authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });
