import React, { memo } from "react";
import { Modal, Button } from "react-bootstrap";

const ShowFilePreviewModal = ({
  previewUrl,
  setPreviewUrl,
  previewModal,
  setPreviewModal,
}) => {
  return (
    <Modal
      onHide={() => {
        setPreviewUrl(null);
        setPreviewModal(false);
      }}
      show={previewModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {previewUrl && previewUrl.endsWith(".png") && (
          <img className="w-100 rounded" src={previewUrl} alt="Preview" />
        )}
        {previewUrl && previewUrl.endsWith(".pdf") && (
          <embed
            src={previewUrl}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center w-100">
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setPreviewModal(false);
            }}
          >
            বন্ধ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ShowFilePreviewModal);
