import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGetIsDistributorQuery } from "../redux/api/applicationCopyrightApiSlice";

const ProtectedRouteDistribute = () => {
  const { data: isDistributor, isLoading: isLoadingDistributor } =
    useGetIsDistributorQuery({ refetchOnMountOrArgChange: true });

  if (isLoadingDistributor) {
    return "";
  }

  if (!isDistributor?.success) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedRouteDistribute;
