import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertToBanglaNumerals } from "../../utils/convertToBanglaNumerals";
import CommentCell from "../CommentCell";

const CopyrightTable = ({ data }) => {
  const getTitle = (selfType) => {
    switch (selfType) {
      case 1:
        return "প্রণেতা / রচয়িতা";
      case 2:
        return "রিলেটেড রাইট";
      case 3:
        return "হস্তান্তর সুত্রে";
      case 4:
        return "নিয়োগকর্তা সূত্রে";
      case 5:
        return "উত্তরাধিকার সূত্রে";
      case 6:
        return "অভিযোজন সূত্রে";
      case 7:
        return "অনুবাদক";
      case 8:
        return "অন্যান্য সূত্রে";
      default:
        return "";
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Table striped bordered>
        <thead>
          <tr className="reg_book">
            <th>ক্রমিক নং </th>
            <th> নথি নাম্বার </th>
            <th>নিবন্ধন সংখ্যা </th>
            <th>আবেদনকারীর নাম, ঠিকানা, জাতীয়তা </th>
            <th>
              {" "}
              রচনা/কর্মের কপিরাইটে আবেদনকারীর <br />
              স্বার্থের প্রকৃতি{" "}
            </th>
            <th>রচনা/কর্মের শ্রেণী ও বর্ণনা </th>
            <th>রচনা/কর্মের নাম </th>
            <th>রচনা/কর্মের ভাষা </th>
            <th>
              রচিয়তা/প্রণেতার নাম, ঠিকানা, <br /> জাতীয়তা এবং মৃত্যুর তারিখ{" "}
            </th>
            <th>রচনা/কর্ম প্রকাশিত/অপ্রকাশিত </th>
            <th>
              প্রথম প্রকাশনার বৎসর ও দেশের নাম এবং <br /> প্রকাশকের নাম, ঠিকানা
              ও জাতীয়তা{" "}
            </th>
            <th>
              পরবর্তীতে কোন প্রকাশনা হয়ে থাকলে তার <br />
              বৎসর ও দেশের নাম এবং প্রকাশকের নাম,
              <br />
              ঠিকানা ও জাতীয়তা{" "}
            </th>
            <th>
              কপিরাইটে অন্য কোন অংশীদার থাকলে তাহার
              <br />
              নাম, ঠিকানা ও জাতীয়তা এবং কপিরাইটে তাদের
              <br />
              প্রত্যেকের অংশের পরিমান সহ কে কি স্বত্ববান তার <br />
              বিবরন(লাইসেন্স ও অধিকার পত্র থাকলে তা সহ){" "}
            </th>
            <th>
              অন্য কাউকে কপিরাইট বা অনুমতি পত্র <br />
              প্রদানের ক্ষমতা দেওয়া হয়ে থাকলে তাদের
              <br />
              নাম, ঠিকানা ও জাতীয়তা{" "}
            </th>
            <th>
              চারু শিল্প সম্পর্কিত কর্ম হলে সেটির <br />
              মূল কপি কোথায় এবং কার দখলে আছে <br />
              তার নাম, ঠিকানা ও জাতীয়তা (স্থাপত্য <br />
              কর্ম হলে সম্পূর্ণ হওয়ার বছর){" "}
            </th>
            <th style={{ width: "400px" }}>
              মন্তব্য&nbsp;(&nbsp;যদি&nbsp;থাকে&nbsp;)
            </th>
          </tr>
        </thead>
        <tbody className="reg_book_content">
          {data?.data.map((archives, index) => {
            const pd = archives.profileDetail;
            const ac = archives.archive;
            const pdt = pd.profileDetailType[0];

            let title = getTitle(Number(pd.selfType));
            let cat_subs = pd.category.name;

            if (pd.subCategory) {
              cat_subs += `, ${pd.subCategory.name}`;
            }

            return (
              <tr
                key={index}
                className={
                  Number(ac.certificate_canceled) === 1 ? "bg-danger" : ""
                }
              >
                <td>{convertToBanglaNumerals(index + 1)}</td>
                <td>
                  {Number(ac.certificate_canceled) === 1 ? (
                    archives.trackReg
                  ) : (
                    <Link to={`/edit/${ac.id}/`}>{archives.trackReg}</Link>
                  )}
                </td>
                <td className="text-center">{ac.registration_no || "---"}</td>
                <td>
                  {`${pdt.name || ""}, ${pdt.address || ""}, ${
                    pdt.nationality || ""
                  }`}
                </td>
                <td>{title}</td>
                <td>{cat_subs}</td>
                <td>{pd.workTitle}</td>
                <td>{pd.workLanguage}</td>
                <td>
                  <ul
                    className={
                      pd?.writerDetails.length > 1 ? "push-down-0" : ""
                    }
                  >
                    {pd.writerDetails.map((writerdetail, writerIndex) => (
                      <li
                        key={writerIndex}
                        className={`text-left ${
                          pd.writerDetails.length > 1 ? "text-des" : "text-desn"
                        }`}
                      >
                        {`${writerdetail.writerName || ""}, ${
                          writerdetail.writerAddress || ""
                        }, ${writerdetail.writerNationality || ""}, ${
                          writerdetail.writerDeadDate
                            ? writerdetail.writerDeadDate
                            : ""
                        }`}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  {Number(pd.workPublishtype) === 1 ? "প্রকাশিত" : "অপ্রকাশিত"}
                </td>
                <td>
                  {Number(pd.workPublishtype) === 1
                    ? `${pd.publishDetails[0]?.year}, ${pd.publishDetails[0]?.place}, ${pd.publishDetails[0]?.name}, ${pd.publishDetails[0]?.address}, ${pd.publishDetails[0]?.nationality}`
                    : "প্রযোজ্য নহে"}
                </td>
                <td>
                  <ul
                    className={
                      pd.publishDetails.length > 1 ? "push-down-0" : ""
                    }
                  >
                    {pd.otherPublishtype === 1
                      ? pd.publishDetails.map((publishdetail, publishIndex) => (
                          <li
                            key={publishIndex}
                            className={`text-left ${
                              pd.publishDetails.length > 1
                                ? "text-des"
                                : "text-desn"
                            }`}
                          >
                            {`${publishdetail?.year}, ${publishdetail?.place}, ${publishdetail?.name}, ${publishdetail?.address}, ${publishdetail?.nationality}`}
                          </li>
                        ))
                      : "প্রযোজ্য নহে"}
                  </ul>
                </td>
                <td>
                  <ul className={pd.ownerTypes.length > 1 ? "push-down-0" : ""}>
                    {Number(pd.ownerType) === 1
                      ? pd.ownerTypes.map((ownertype, ownerIndex) => (
                          <li
                            key={ownerIndex}
                            className={`text-left ${
                              pd.ownerTypes.length > 1
                                ? "text-des"
                                : "text-desn"
                            }`}
                          >
                            {`${ownertype?.ownerName}, ${ownertype?.ownerAddress}, ${ownertype?.ownerNationality}, ${ownertype?.partTitle}`}
                          </li>
                        ))
                      : "প্রযোজ্য নহে"}
                  </ul>
                </td>
                <td>
                  <ul className={pd.powerTypes.length > 1 ? "push-down-0" : ""}>
                    {Number(pd.powerType) === 1
                      ? pd.powerTypes.map((powertype, powerIndex) => (
                          <li
                            key={powerIndex}
                            className={`text-left ${
                              pd.powerTypes.length > 1
                                ? "text-des"
                                : "text-desn"
                            }`}
                          >
                            {`${powertype?.name}, ${powertype?.address}, ${powertype?.nationality}`}
                          </li>
                        ))
                      : "প্রযোজ্য নহে"}
                  </ul>
                </td>
                <td>
                  {pd.category?.name === 3
                    ? `${pd.industryActions[0]?.place}, ${
                        pd.industryActions[0]?.name
                      }, ${pd.industryActions[0]?.address}, ${
                        pd.industryActions[0]?.nationality
                      }, ${pd.industryActions[0]?.endYear || ""}`
                    : "প্রযোজ্য নহে"}
                </td>
                <td>
                  <CommentCell maxLength={30} text={ac.comments} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CopyrightTable;
