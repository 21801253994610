import React, { useEffect, useState } from "react";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import Select from "react-select";
import styles from "./ContractCopyTwo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoriesQuery } from "../../../../redux/api/categoryApiSlice";
import { transformErrorsToObjectStructure } from "../../../../utils/transformErrorsToObjectStructure";
import toast from "react-hot-toast";
import validationSchema, { initialValues } from "./validation/ContractTwo";
import { useFormik } from "formik";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import { useLazyGetSubCategoryCatalogQuery } from "../../../../redux/api/categoryCatalogApiSlice";
import { globalDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../form_two/BanglaCalender";
import Loader from "../../../../components/custom/Loader";
import { BeatLoader } from "react-spinners";
import {
  useGetAgreementDetailsQuery,
  useGetAgreementsQuery,
  useUpdateAgreementDetailsMutation,
} from "../../../../redux/api/agreementsApiSlice";
import { apiSlice } from "../../../../redux/api/apiSlice";
import { useDispatch } from "react-redux";

export default function ContractCopyTwoUpdate() {
  const dispatch = useDispatch();
  const { data: categoryData } = useGetCategoriesQuery();
  const [fetchSubCategory, { data: subCategoryData }] =
    useLazyGetSubCategoryCatalogQuery();
  const [isPreview, setIsPreview] = useState(false);

  const { contractId, agreementId } = useParams();
  const { data: contractOneData, isLoading: isLoadingContract } =
    useGetAgreementsQuery(contractId);
  const contractOne = contractOneData?.data;
  const {
    data: AgreementDetailsData,
    isLoading: isLoadingAgreementDetails,
    isSuccess: isSuccessAgreement,
  } = useGetAgreementDetailsQuery(agreementId);
  const agreementDetails = AgreementDetailsData?.data;

  const navigate = useNavigate();
  const [
    updateAgreement,
    {
      isLoading: isLoadingUpdate,
      error: errorUpdate,
      isError: isErrorUpdate,
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateAgreementDetailsMutation();

  /// update a new agreement
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        updateAgreement({ id: contractOne?.id, formData: values });
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (contractId) {
      setValues((prev) => ({
        ...prev,
        agreement_id: contractId,
      }));
    }
  }, [contractId]);

  useEffect(() => {
    if (isSuccessAgreement) {
      fetchSubCategory({ parent_id: agreementDetails.category.id });
      setValues((prev) => ({
        ...prev,
        _method: "PUT",
        agreement_id: Number(agreementDetails.id),
        date: agreementDetails.contractdate,
        nationality: agreementDetails.nationality,
        source: Number(agreementDetails.source),
        onwer: agreementDetails.onwer,
        address: agreementDetails?.profile?.communicationAddress,
        exchangerate: agreementDetails.exchangerate,
        documentexpire: agreementDetails.documentexpire,
        quantity: agreementDetails.quantity,
        category_id: agreementDetails.category.id,
        subCategory: agreementDetails.subCategory.id,
        worktitle: agreementDetails.worktitle,
        geoperimeter: agreementDetails.geoperimeter,
        extensions: agreementDetails.extensions,
        profiletypesa: agreementDetails.profiletypea,
        profiletypesb: agreementDetails.profiletypeb,
        profiletypesc: agreementDetails.profiletypec,
        profiletypesd: agreementDetails.profiletyped,
      }));
    }
  }, [isSuccessAgreement]);

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      if (!isPreview) {
        dispatch(apiSlice.util.resetApiState());
        navigate(`/agreement-form-add-two/${dataUpdate?.data?.id}/preview`);
      } else {
        navigate(`/applicant/application-payment/${contractId}`);
      }
      resetForm();
      toast.success(dataUpdate?.message);
    }
    // eslint-disable-next-line
  }, [isErrorUpdate, isSuccessUpdate]);

  // Multiple item update and remove functions

  const newFieldSet = {
    name: "",
    fathername: "",
    mothername: "",
    address: "",
    nidno: "",
    mobile: "",
    email: "",
  };
  // step 4
  const handleAddFieldSetFirstSide = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesa: [...prevValues.profiletypesa, newFieldSet],
    }));
  };

  const handleRemoveFieldSetFirstSide = (idToRemove) => {
    const updatedFieldSets = values.profiletypesa.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiletypesa: updatedFieldSets,
    }));
  };

  const handleAddFieldSetSecondSide = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesb: [...prevValues.profiletypesb, newFieldSet],
    }));
  };

  const handleRemoveFieldSetSecondSide = (idToRemove) => {
    const updatedFieldSets = values.profiletypesb.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiletypesb: updatedFieldSets,
    }));
  };

  const handleAddFieldSetFirstWitness = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesc: [...prevValues.profiletypesc, newFieldSet],
    }));
  };

  const handleRemoveFieldSetFirstWitness = (idToRemove) => {
    const updatedFieldSets = values.profiletypesc.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiletypesc: updatedFieldSets,
    }));
  };

  const handleAddFieldSetSecondWitness = () => {
    setValues((prevValues) => ({
      ...prevValues,
      profiletypesd: [...prevValues.profiletypesd, newFieldSet],
    }));
  };

  const handleRemoveFieldSetSecondWitness = (idToRemove) => {
    const updatedFieldSets = values.profiletypesd.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiletypesd: updatedFieldSets,
    }));
  };

  // Selected defoult items
  // Mapping the category data to create options for the select component
  const WorkTypeLists = categoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  // Finding the default category based on the value
  const catDefault = WorkTypeLists?.find(
    (data) => data.value === values.category_id
  );

  // Mapping the sub-category data to create options for the select component
  const WorkTypeSubList = subCategoryData?.data?.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  // Finding the default sub-category based on the value
  const subCatDefaultData = subCategoryData?.data?.find(
    (data) => data.id === values.subCategory
  );

  const subCatDefault = subCatDefaultData
    ? {
        value: subCatDefaultData.id,
        label: subCatDefaultData.name,
      }
    : null;

  const handleCatChange = (selectedOption) => {
    setValues((prevValues) => ({
      ...prevValues,
      category_id: selectedOption.value,
      subCategory: "",
    }));

    fetchSubCategory({ parent_id: values.category_id });
  };
  const handleSourceChange = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      source: val,
    }));
  };

  const toggleCheckIsAgree = () => {
    setValues((prev) => ({
      ...prev,
      form_status: values.form_status === 1 ? 2 : 1,
    }));
    setIsPreview((prevState) => !prevState);
  };

  if (Number(agreementDetails?.data?.document?.form_status) === 2) {
    navigate(`/agreement-form-add-two/${agreementId}/preview`);
  }
  if (isLoadingContract || isLoadingAgreementDetails) {
    return <Loader />;
  }
  return (
    <div>
      <section className={`${styles.section} py-5 service-single`}>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center bg-light">
            <div className="card p-3 shadow w-100">
              <nav>
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    চুক্তিপত্রের জন্য আবেদন ২য় পৃষ্ঠা
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-3 border bg-light"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className={styles.applicationForm}>
                    <div className="applicationForm-header text-center add2-application-header">
                      <h2>বিষয়াবলীর বিবরণী</h2>
                      <h5>
                        <b>(দুটি প্রতিলিপি পাঠাতে হবে)</b>
                      </h5>
                    </div>

                    <div className="add2-section-1">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>১. নিবন্ধন সংখ্যাঃ</b>
                            </h5>
                          </div>
                          <div className="add2-panel-background">
                            <div className="form-group">
                              <input
                                type="text"
                                name="address"
                                class="form-control"
                                placeholder="কপিরাইট অফিস কর্তৃক পূরণীয়"
                                id="address"
                                data-content="নিবন্ধন সংখ্যা"
                                data-toggle="popover"
                                data-trigger="active"
                                data-placement="top"
                                disabled="disabled"
                                data-original-title=""
                                title=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>২. আবেদনকারীর তথ্য</b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>আবেদনকারীর নাম</label>
                                  <input
                                    placeholder="আবেদনকারীর নাম"
                                    type="email"
                                    className="form-control"
                                    value={contractOne?.puname}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>ই-মেইল</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="ই-মেইল"
                                    value={contractOne?.user?.email}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>মোবাইল নম্বর</label>
                                  <input
                                    disabled
                                    type="number"
                                    className="form-control"
                                    value={contractOne?.mobileNo}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>ঠিকানা</label>
                                  <ClientFormikFormFieldMultiple
                                    placeholder={"ঠিকানা"}
                                    value={values.address}
                                    name={`address`}
                                    errors={errors?.address && errors?.address}
                                    touched={
                                      touched?.address && touched?.address
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-2">
                                  <label>জাতীয়তা</label>
                                  <ClientFormikFormFieldMultiple
                                    placeholder={"জাতীয়তা"}
                                    value={values.nationality}
                                    name={`nationality`}
                                    errors={
                                      errors?.nationality && errors?.nationality
                                    }
                                    touched={
                                      touched?.nationality &&
                                      touched?.nationality
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-3 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৩. পক্ষদ্বয়ের নাম ও ঠিকানা</b>
                            </h5>
                          </div>

                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">
                              ক. ১ম পক্ষ সত্মনিয়োগকর্তা / লাইসেন্স প্রদানকারী
                            </b>

                            {values.profiletypesa.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পূর্ণ নাম"
                                      value={item.name}
                                      name={`profiletypesa[${index}].name`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesa[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesa[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesa[${index}].address`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesa[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.mobile}
                                      name={`profiletypesa[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.email}
                                      name={`profiletypesa[${index}].email`}
                                      errors={
                                        errors?.profiletypesa &&
                                        errors.profiletypesa?.length > index &&
                                        errors?.profiletypesa[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesa &&
                                        touched.profiletypesa?.length > index &&
                                        touched?.profiletypesa[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesa.length ===
                                        index + 1 ||
                                        values.profiletypesa.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={handleAddFieldSetFirstSide}
                                        />
                                      )}
                                      {values.profiletypesa.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetFirstSide(
                                                index
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-4 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">
                              খ. ২য় পক্ষ সত্মনিয়োগী / লাইসেন্স গ্রহীতা
                            </b>

                            {values.profiletypesb.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পূর্ণ নাম"
                                      value={item.name}
                                      name={`profiletypesb[${index}].name`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesb[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesb[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesb[${index}].address`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesb[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.mobile}
                                      name={`profiletypesb[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.email}
                                      name={`profiletypesb[${index}].email`}
                                      errors={
                                        errors?.profiletypesb &&
                                        errors.profiletypesb?.length > index &&
                                        errors?.profiletypesb[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesb &&
                                        touched.profiletypesb?.length > index &&
                                        touched?.profiletypesb[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesb.length ===
                                        index + 1 ||
                                        values.profiletypesb.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={handleAddFieldSetSecondSide}
                                        />
                                      )}
                                      {values.profiletypesb.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetSecondSide(
                                                index
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-5 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৪. সাক্ষীর নাম ও ঠিকানা</b>
                            </h5>
                          </div>

                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">ক. ১ম সাক্ষী</b>

                            {values.profiletypesc.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পূর্ণ নাম"
                                      value={item.name}
                                      name={`profiletypesc[${index}].name`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesc[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesc[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesc[${index}].address`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesc[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.mobile}
                                      name={`profiletypesc[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.email}
                                      name={`profiletypesc[${index}].email`}
                                      errors={
                                        errors?.profiletypesc &&
                                        errors.profiletypesc?.length > index &&
                                        errors?.profiletypesc[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesc &&
                                        touched.profiletypesc?.length > index &&
                                        touched?.profiletypesc[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesc.length ===
                                        index + 1 ||
                                        values.profiletypesc.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={
                                            handleAddFieldSetFirstWitness
                                          }
                                        />
                                      )}
                                      {values.profiletypesc.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetFirstWitness(
                                                index
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-6 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2panelbackground}>
                            <b className="mb-2 d-block">খ. ২য় সাক্ষী</b>

                            {values.profiletypesd.map((item, index) => (
                              <div key={index} className="row mb-2">
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পূর্ণ নাম"
                                      value={item.name}
                                      name={`profiletypesd[${index}].name`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.name
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.name
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="পিতার নাম"
                                      value={item.fathername}
                                      name={`profiletypesd[${index}].fathername`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.fathername
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]
                                          ?.fathername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মাতার নাম"
                                      value={item.mothername}
                                      name={`profiletypesd[${index}].mothername`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.mothername
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]
                                          ?.mothername
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ঠিকানা"
                                      value={item.address}
                                      name={`profiletypesd[${index}].address`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.address
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.address
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="ভোটার আইডি নং"
                                      value={item.nidno}
                                      name={`profiletypesd[${index}].nidno`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.nidno
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.nidno
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.mobile}
                                      name={`profiletypesd[${index}].mobile`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.mobile
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.mobile
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-2">
                                    <ClientFormikFormFieldMultiple
                                      placeholder="মোবাইল নং"
                                      value={item.email}
                                      name={`profiletypesd[${index}].email`}
                                      errors={
                                        errors?.profiletypesd &&
                                        errors.profiletypesd?.length > index &&
                                        errors?.profiletypesd[index]?.email
                                      }
                                      touched={
                                        touched?.profiletypesd &&
                                        touched.profiletypesd?.length > index &&
                                        touched?.profiletypesd[index]?.email
                                      }
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      {(values.profiletypesd.length ===
                                        index + 1 ||
                                        values.profiletypesd.length === 1) && (
                                        <ImPlus
                                          className="customAddButton p-2"
                                          onClick={
                                            handleAddFieldSetSecondWitness
                                          }
                                        />
                                      )}
                                      {values.profiletypesd.length > 1 &&
                                        index !== 0 && (
                                          <IoCloseSharp
                                            className="customRemoveButton"
                                            onClick={() =>
                                              handleRemoveFieldSetSecondWitness(
                                                index
                                              )
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-7 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>
                                ৫. কর্মটির শ্রেণি/উপশ্রেণি এবং নাম (যদি থাকে)
                              </b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <div>
                                    <Select
                                      options={WorkTypeLists}
                                      value={
                                        catDefault || {
                                          value: "",
                                          label: "-- শ্রেণি --",
                                          isDisabled: true,
                                        }
                                      }
                                      classNamePrefix="react-select"
                                      onChange={handleCatChange}
                                    />
                                  </div>
                                  <div>
                                    {errors.category_id &&
                                      touched.category_id && (
                                        <small className="my-2 text-danger">
                                          {errors.category_id}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <div>
                                    <Select
                                      options={WorkTypeSubList}
                                      value={
                                        subCatDefault || {
                                          value: "",
                                          label: "-- উপশ্রেণি --",
                                          isDisabled: true,
                                        }
                                      }
                                      classNamePrefix="react-select"
                                      onChange={(selectedOption) => {
                                        setValues((prevValues) => ({
                                          ...prevValues,
                                          subCategory: selectedOption.value,
                                        }));
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div>
                                    {errors.subCategory &&
                                      touched.subCategory && (
                                        <small className="my-2 text-danger">
                                          {errors.subCategory}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group mb-2">
                                  <ClientFormikFormFieldMultiple
                                    placeholder="নাম"
                                    value={values.worktitle}
                                    name={`worktitle`}
                                    errors={
                                      errors?.worktitle && errors?.worktitle
                                    }
                                    touched={
                                      touched?.worktitle && touched?.worktitle
                                    }
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-8 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>৬. সত্ত্বাধিকারী স্বত্ব প্রাপ্তির উৎস</b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="pc1"
                                    checked={values.source === 1}
                                    onClick={() => handleSourceChange(1)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="pc1"
                                  >
                                    প্রণেতা
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="pc2"
                                    checked={values.source === 2}
                                    onClick={() => handleSourceChange(2)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="pc2"
                                  >
                                    বিনিয়োগকারী প্রযোজক
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso3"
                                    checked={values.source === 3}
                                    onClick={() => handleSourceChange(3)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso3"
                                  >
                                    উত্তরাধিকার
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso4"
                                    checked={values.source === 4}
                                    onClick={() => handleSourceChange(4)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso4"
                                  >
                                    ক্রয়সূত্রে
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="praptirUtso"
                                    id="praptirUtso5"
                                    checked={values.source === 5}
                                    onClick={() => handleSourceChange(5)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="praptirUtso5"
                                  >
                                    সম্পাদনকারী
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-9 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={styles.add2sectiontitle}>
                            <h5>
                              <b>
                                ৭. যে নির্দিষ্ট স্বত্বের অধিকার প্রদান করা হয়েছে
                                (প্রযোজ্য ক্ষেত্রে)
                              </b>
                            </h5>
                          </div>
                          <div className={styles.add2panelbackground}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <ClientFormikFormFieldMultiple
                                    value={values.onwer}
                                    name={`onwer`}
                                    errors={errors?.onwer && errors?.onwer}
                                    touched={touched?.onwer && touched?.onwer}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-10 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>৮. বিনিময় মূল্য</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.exchangerate}
                                        name={`exchangerate`}
                                        errors={
                                          errors?.exchangerate &&
                                          errors?.exchangerate
                                        }
                                        touched={
                                          touched?.exchangerate &&
                                          touched?.exchangerate
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-11 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>৯. দলিল / লাইসেন্সের মেয়াদ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        placeholder="-- বছর"
                                        value={values.documentexpire}
                                        name={`documentexpire`}
                                        errors={
                                          errors?.documentexpire &&
                                          errors?.documentexpire
                                        }
                                        touched={
                                          touched?.documentexpire &&
                                          touched?.documentexpire
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-12 mt-3">
                          <div className="row">
                            <div className="col-md-13">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১০. দলিল সম্পাদনের তারিখ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <BanglaCalender
                                        placeholder={"তারিখ"}
                                        value={values.date}
                                        onChange={(date) => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            date: globalDateFormat(date),
                                          }));
                                        }}
                                      />
                                      <div>
                                        {" "}
                                        {errors?.date && touched?.date && (
                                          <small className="my-2 text-danger">
                                            {errors?.date}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-14 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১১ . রয়্যালিটির পরিমাণ</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.quantity}
                                        name={`quantity`}
                                        errors={
                                          errors?.quantity && errors?.quantity
                                        }
                                        touched={
                                          touched?.quantity && touched?.quantity
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="add2-section-15 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>১২. স্বত্ব নিয়োগের ভৌগোলিক পরিধি</b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.geoperimeter}
                                        name={`geoperimeter`}
                                        errors={
                                          errors?.geoperimeter &&
                                          errors?.geoperimeter
                                        }
                                        touched={
                                          touched?.geoperimeter &&
                                          touched?.geoperimeter
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add2-section-16 mt-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className={styles.add2sectiontitle}>
                                <h5>
                                  <b>
                                    ১৩. পুনঃপরীক্ষণ, বর্ধিতকরণ ও বন্ধকরণ
                                    (প্রযোজ্য নয়)
                                  </b>
                                </h5>
                              </div>
                              <div className={styles.add2panelbackground}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group mb-2">
                                      <ClientFormikFormFieldMultiple
                                        value={values.extensions}
                                        name={`extensions`}
                                        errors={
                                          errors?.extensions &&
                                          errors?.extensions
                                        }
                                        touched={
                                          touched?.extensions &&
                                          touched?.extensions
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-9 col-12">
                        <div className="d-flex align-items-center gap-3 pt-5">
                          <div className="d-flex mx-3">
                            <div className={`${styles.customCheckbox} mx-3`}>
                              <label
                                className="form-check-label d-flex align-items-center"
                                htmlFor="checkIsAgree"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkIsAgree"
                                  id="checkIsAgree"
                                  onClick={toggleCheckIsAgree}
                                />
                                <span
                                  class={`${styles.checkmark}`}
                                  title="অঙ্গীকারনামা ভালো করে পড়ুন , সম্মত হলে নির্বাচন করে দাখিল করুন ।"
                                ></span>
                              </label>
                            </div>
                          </div>
                          <div>
                            <p>
                              আমি / আমরা ঘোষণা করছি যে, কপিরাইট রেজিস্ট্রেশনের
                              আবেদনে আমার পূরণকৃত সকল তথ্য ও সংযুক্ত দলিলসমূহ
                              সত্য ও নির্ভুল এবং এ কর্মটিকে কেন্দ্র করে কোন
                              মামলা কোন বিজ্ঞ আদালত বা ট্রাইব্যুনালে বিচারাধীন
                              নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও অসত্য তথ্য
                              উদ্‌ঘাটিত হলে আমি / আমরা এর জন্য ব্যক্তিগতভাবে
                              দায়ী থাকব এবং দেশের প্রচলিত আইন অনুযায়ী যেকোন দন্ড
                              গ্রহণে বাধ্য হবো ।
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                    <div className="submit-area mt-2">
                      <div className="d-flex gap-3 justify-content-center">
                        <buttton
                          className="custombtn5"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={isLoadingUpdate}
                        >
                          {isLoadingUpdate ? (
                            <BeatLoader color="#fff" />
                          ) : (
                            "প্রিভিউ ও সংরক্ষণ"
                          )}
                        </buttton>
                        <buttton
                          className="custombtn5"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={isLoadingUpdate}
                        >
                          {isLoadingUpdate ? (
                            <BeatLoader color="#fff" />
                          ) : (
                            "দাখিল করুন"
                          )}
                        </buttton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
