import React from 'react';
import { Link } from 'react-router-dom';
import { useGetCatalogDocumentQuery } from '../../redux/api/catalogdocumentApiSlice';
import Loader from '../../components/custom/Loader';

export default function Documents() {
    const { data, isLoading} = useGetCatalogDocumentQuery();
    const filteredData = data?.data;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className="breadcrumbs" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="breadcrumbs-content left">
                                <h1 className="page-title">সংযুক্তির নমুনা</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="breadcrumbs-content right">
                                <ul className="breadcrumb-nav">
                                    <li><Link to="/">হোম পেইজ</Link></li>
                                    <li>সংযুক্তির নমুনা</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="pt-5 mb-5 pb-0 section">
                <div className="container" data-aos="fade-up">
                    <div className="row mx-1 justify-content-center">
                        <div className="col-lg-12 card">
                            <div className="row">
                                <div className="col-md-12 py-2 d-flex justify-content-center">
                                    <ul className="nav nav-pills w-100" id="myTab" role="tablist">
                                        {filteredData?.map((document, index) => (
                                            <li className="nav-item" key={document.id} role="presentation">
                                                <a
                                                    className={`nav-link border my-1 mx-1 ${index === 0 ? 'active' : ''}`}
                                                    id={`document_tab_${document.id}`}
                                                    data-bs-toggle="tab"
                                                    href={`#document_tab_content_${document.id}`}
                                                    role="tab"
                                                    aria-controls={`document_tab_content_${document.id}`}
                                                    aria-selected={index === 0 ? "true" : "false"}
                                                >
                                                    {document.description}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-12">
                                    <div className="tab-content" id="myTabContent">
                                        {filteredData?.map((document, index) => (
                                            <div
                                                className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                                id={`document_tab_content_${document.id}`}
                                                key={`tab_content_${document.id}`}
                                                role="tabpanel"
                                                aria-labelledby={`document_tab_${document.id}`}
                                            >
                                                <div className="card border-0">
                                                    <div className="card-body">
                                                        <iframe src={document.document} width="100%" height="900px" title={document.description} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}