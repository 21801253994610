import React, { useCallback, useEffect, useState } from "react";
import { Copyright, Handshake, Home, Print } from "@mui/icons-material";
import SubmittedCommon from "../../../../components/SubmittedCommon";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import debounce from "lodash/debounce";
import {
  useGetSubmittedAgreementQuery,
  useGetSubmittedCopyrightQuery,
} from "../../../../redux/api/submittedCopyrightAgreementApiSlice";
import ReusableTable from "../../../../components/custom/data_table/ReusableTable";
import { localDateFormat } from "../../../../utils/dateFormat";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../utils/authorization";
import {
  detailsUrlAgreement,
} from "../../../../utils/detailsLink";
import { FaEye } from "react-icons/fa";
import { Button } from "react-bootstrap";
import AdminFullFormAgreement from "../../../../components/full-form-admin/AdminFullFormAgreement";
import CountCategoryAgreement from "../../../../components/count_category/CountCategoryAgreement";

const SubmittedAgreement = ({
  fileStatus,
  applicationPath,
  detailsRoute,
  pageTitle,
}) => {
  const [previewData, setPreviewData] = useState("");
  const [preview, setPreview] = useState(false);
  const [date, setDate] = useState("");
  const [catId, setCatId] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { authUser } = useAuth();

  const { data, isLoading, refetch } = useGetSubmittedAgreementQuery({
    date,
    trackReg: searchValue,
    category_id,
    subCategory,
    page,
    fileStatus,
  });

  /// copyright application length
  const { data: copyright } = useGetSubmittedCopyrightQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
    fileStatus,
  });
  /// agreement application length
  const { data: agreement } = useGetSubmittedAgreementQuery({
    date: "",
    trackReg: "",
    category_id: "",
    subCategory: "",
    page: "",
    fileStatus,
  });

  const total = {
    copyright: copyright?.meta?.total ?? 0,
    agreement: agreement?.meta?.total ?? 0,
  };

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Handshake,
      title: pageTitle ? "চুক্তিপত্র - " + pageTitle : "চুক্তিপত্র আবেদন পত্র",
      active: true,
    },
  ];

  // data table start
  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  const columns = [
    { label: "তারিখ", name: "sendDate" },
    {
      label: "আবেদন নং",
      name: "trackReg",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData.id;
          return (
            <Link
              className="text-primary"
              to={
                detailsRoute
                  ? `${detailsRoute}/${id}`
                  : `${detailsUrlAgreement(authUser.role_id, id)}`
              }
            >
              {value}
            </Link>
          );
        },
      },
    },
    { label: "আবেদনকারির নাম", name: "applicant" },
    {
      label: "শিরোনাম",
      name: "workTitle",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData.id;
          return (
            <Link
              className="text-primary"
              to={
                detailsRoute
                  ? `${detailsRoute}/${id}`
                  : `${detailsUrlAgreement(authUser.role_id, id)}`
              }
            >
              {value}
            </Link>
          );
        },
      },
    },

    { label: "বিভাগ", name: "category" },
    { label: "উপবিভাগ", name: "subCategory" },
    {
      label: "দেখুন",
      name: "id",
      options: {
        customBodyRender: (value, tableMeta) => {
          const trackReg = tableMeta.rowData.trackReg;
          return (
            <span
              onClick={() => {
                setPreviewData({
                  id: value,
                  trackReg: trackReg,
                });
                setPreview(true);
              }}
            >
              <FaEye className="text-primary fs-3 cursor-pointer" />
            </span>
          );
        },
      },
    },
  ];

  const dataTable = data?.data.map((item, i) => ({
    sendDate: localDateFormat(item?.sendDate),
    trackReg: item?.trackReg,
    id: item?.id,
    applicant: item?.puname,
    workTitle: item?.document?.workTitle,
    category: item?.document?.category?.name,
    subCategory: item?.document?.subCategory?.name,
  }));

  // if (isLoading) {
  //   return <Loader />;
  // }

  const onPreview = () => {
    setPreview(false);
    setPreviewData("");
  };

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-3">
        <div className="pb-3">
          <SubmittedCommon
            preview={preview}
            onPreview={onPreview}
            total={total}
            applicationPath={applicationPath}
            date={date}
            setDate={setDate}
            category_id={category_id}
            setCategoryId={setCategoryId}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
          />
        </div>

        {fileStatus === 5 && (
          <CountCategoryAgreement
            category_id={catId}
            setCategoryId={setCatId}
            fileStatus={fileStatus}
          />
        )}

        <div>
          {!previewData && !preview ? (
            <ReusableTable
              title={"চুক্তিপত্র আবেদন"}
              columns={columns}
              data={dataTable}
              isLoading={isLoading}
              currentPage={page}
              lastPage={lastPage}
              totalData={totalData}
              onSearchChange={handleSearchChange}
              searchText={searchText}
              onPageChange={handlePageChange}
            />
          ) : (
            <div>
              <AdminFullFormAgreement info={previewData} />

              <div className="text-center">
                <Button
                  size="sm"
                  variant="danger"
                  className="px-3"
                  onClick={onPreview}
                >
                  মুছে ফেলুন
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmittedAgreement;
