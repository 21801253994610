import { APPLICATION_FORM_TWO_CREATE } from "../constants";
import { apiSlice } from "./apiSlice";

const copyrightApplicationFormTwoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findApplicationFormTwo: builder.query({
      query: (id) => APPLICATION_FORM_TWO_CREATE + "/" + id,
      providesTags: ["applicant_edit_form_two"]
    }),
    updateApplicationFormTwo: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_FORM_TWO_CREATE + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["applicant_edit_form_two"],
    }),
    createApplicationFormTwo: builder.mutation({
      query: (formData) => ({
        url: APPLICATION_FORM_TWO_CREATE,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["FormTwo"],
    }),
    deleteApplicationFormTwo: builder.mutation({
      query: (id) => ({
        url: APPLICATION_FORM_TWO_CREATE + "/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["FormTwo"],
    }),

    getFullFormPreview: builder.query({
      query: (id)=> `/api/applicant/applications/${id}/edit`
    }),
    
  }),
});

export const {
  useFindApplicationFormTwoQuery,
  useUpdateApplicationFormTwoMutation,
  useCreateApplicationFormTwoMutation,
  useDeleteApplicationFormTwoMutation,
  useGetFullFormPreviewQuery,
} = copyrightApplicationFormTwoApiSlice;
