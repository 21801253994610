import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useGetCatalogRegistrationQuery } from '../../redux/api/catalogRegistrationApiSlice';
import Loader from "../../components/custom/Loader";

const convertToBengaliNumber = (number) => {
    const bengaliDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
    return number.toString().split('').map(digit => bengaliDigits[digit]).join('');
}

export default function Rules() {
    const publicUrl = process.env.PUBLIC_URL;
    const params = useParams();
    const { data, isLoading } = useGetCatalogRegistrationQuery(params?.slug);
    const filteredData = data?.data
     
    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className="breadcrumbs" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="breadcrumbs-content left">
                                <h1 className="page-title">
                                    {filteredData?.[0]?.category?.name ? `কপিরাইট রেজিস্ট্রেশনের নিয়মাবলি ( ${filteredData[0].category.name} )` : `কপিরাইট রেজিস্ট্রেশনের নিয়মাবলি`}
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="breadcrumbs-content right">
                                <ul className="breadcrumb-nav">
                                    <li><Link to="/">হোম পেইজ</Link></li>
                                    <li>কপিরাইট রেজিস্ট্রেশনের নিয়মাবলি</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section pt-5 mb-5 pb-0 service-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 m-auto">
                            <div className="service-details bg-white" style={{ borderTop: '4px solid #55a155', borderRadius: 7 }}>
                                <div className="text-center mt-5 mb-3">
                                    <img src={`${publicUrl}/assets/images/govt_logo.png`} className="img-fluid" style={{ height: 80, width: 80 }} alt="Government Logo" />
                                    <h5 className="mt-3">গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h5>
                                    <h5>কপিরাইট অফিস, বাংলাদেশ।</h5>
                                </div>

                                <div className="content-body">
                                    {filteredData && filteredData.length > 0 ? (
                                        filteredData.map((item, i) => (
                                            <p key={i}>
                                                <strong>{convertToBengaliNumber(i + 1)}.</strong> {item.description}
                                            </p>
                                        ))
                                    ) : (
                                        <h5 className="text-center my-5">No Data vailable</h5>
                                    )}

                                    <div className="row mt-5">
                                        <div className="col-md-4 mb-4">
                                            <span className="iwt">
                                                <i className="fa fa-clock-o icn" />
                                                <span>
                                                    <strong className="arleft">রবিবার - বৃহস্পতিবার - </strong>
                                                    <p className="arupar">সকালঃ ৯.৩০ → বিকালঃ ৫.০০ পর্যন্ত ( সরকারী ছুটি ব্যতীত )</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <span className="iwt">
                                                <i className="fa fa-fax icn" />
                                                <span>
                                                    <strong className="arleft">ফ্যাক্স -</strong>
                                                    <p className="arupar"> +8802-8144895 </p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <span className="iwt">
                                                <i className="fa fa-phone icn" />
                                                <span>
                                                    <strong className="arleft">অতিরিক্ত তথ্যের জন্য যোগাযোগ -</strong>
                                                    <p className="arupar">
                                                        +8801511-440044,
                                                        +8802-48115386
                                                    </p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <span className="iwt">
                                                <i className="fa fa-globe icn" />
                                                <span>
                                                    <strong className="arleft">ওয়েবসাইট -</strong>
                                                    <p className="arupar">
                                                        <Link to="http://www.copyrightoffice.gov.bd" className="acolor" target="_blank">www.copyrightoffice.gov.bd</Link>
                                                    </p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <span className="iwt">
                                                <i className="fa fa-envelope icn" />
                                                <span>
                                                    <strong className="arleft">ই-মেইল -</strong>
                                                    <p className="arupar"> info@copyrightoffice.gov.bd</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4 mb-5">
                                            <span className="iwt">
                                                <span>
                                                    <strong className="arleft">ঠিকানা -</strong>
                                                    <p className="arupar">
                                                        কপিরাইট অফিস,
                                                        সংস্কৃতি বিষয়ক মন্ত্রণালয়,
                                                        জাতীয় গ্রন্থাগার ভবন (৩য় তলা),
                                                        ৩২, বিচারপতি এসএম মোর্শেদ সরণী,
                                                        আগারগাঁও, শেরেবাংলা নগর, ঢাকা-1207.
                                                    </p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="button2 mb-3">
                                                <a href className="btn mouse-dir white-bg">পূরণকৃত ফরম ও নমুনা <span className="dir-part" /></a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="button2">
                                                <a href className="btn mouse-dir white-bg">কপিরাইটের জন্য আবেদন করুন<span className="dir-part" /></a>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <h5>রেজিস্ট্রার অব কপিরাইট </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
