import { APPLICATION_FORM_ONE_CREATE, APPLICATION_LIST } from "../constants";
import { apiSlice } from "./apiSlice";

const copyrightApplicationFormOneApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationsList: builder.query({
      query: ({ searchValue = "", currentPage = 1 }) => {
        let url = `${APPLICATION_LIST}?page=${currentPage}&per_page=10`;
        if (searchValue) {
          url += `&searchText=${searchValue}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    findApplicationFormOne: builder.query({
      query: (id) => APPLICATION_FORM_ONE_CREATE + "/" + id,
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
    updateApplicationFormOne: builder.mutation({
      query: ({ formData, id }) => ({
        url: APPLICATION_FORM_ONE_CREATE + "/" + id,
        method: "POST",
        body: formData,
      }),
    }),
    createApplicationFormOne: builder.mutation({
      query: (formData) => ({
        url: APPLICATION_FORM_ONE_CREATE,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["FormOne"],
    }),
    deleteApplicationFormOne: builder.mutation({
      query: (id) => ({
        url: APPLICATION_FORM_ONE_CREATE + "/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["FormOne", "show_correction_copyright_first_part"],
    }),
  }),
});

export const {
  useGetApplicationsListQuery,
  useFindApplicationFormOneQuery,
  useUpdateApplicationFormOneMutation,
  useCreateApplicationFormOneMutation,
  useDeleteApplicationFormOneMutation,
} = copyrightApplicationFormOneApiSlice;
