import React, { useEffect, useState } from "react";
import { useLazyGetActivityCountHistoryQuery } from "../../../../redux/api/reportApiSlice";
import { Button, Modal } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import {
  globalDateFormat,
  localDateFormat,
} from "../../../../utils/dateFormat";
import { DateRange } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";

const ActivityHistoryCount = ({ show, onHide, data }) => {
  const [fetchHistory, { data: history }] =
    useLazyGetActivityCountHistoryQuery();

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  
  useEffect(() => {
    if (data?.user?.id) {
      setIsLoadingHistory(true); // Set loading to true before fetch
      fetchHistory({
        user_id: data?.user?.id,
        date: globalDateFormat(data?.date),
      }).finally(() => setIsLoadingHistory(false)); // Reset loading after fetch
    }
  }, [data?.user?.id]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <label>
          {data?.user?.name} ( {data?.user?.role?.bn_name} )
        </label>
      </Modal.Header>
      <Modal.Body>
        {isLoadingHistory ? (
          <div className="text-center">
            <BeatLoader />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="mb-2">
                    <DateRange sx={{ fontSize: "18px" }} /> কার্যক্রম -{" "}
                    {localDateFormat(data?.date)}
                  </label>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রেরিত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {history?.data?.total?.sent_files}
                      </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রাপ্ত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {history?.data?.total?.received_files}
                      </Badge>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <Button size="sm" variant="secondary" onClick={onHide}>
            বন্ধ করুন
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityHistoryCount;
