import { DateRange } from "@mui/icons-material";
import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { localDateFormat } from "../../utils/dateFormat";
import { useGetActivityCountHistoryQuery } from "../../redux/api/reportApiSlice";
import { Card } from "@mui/material";
import { theme } from "../../theme";

const FileHistoryCount = () => {
  const { data, isLoading } = useGetActivityCountHistoryQuery({});

  return (
    <div className="slideUp">
      <Card
        sx={{
          boxShadow: theme.palette.boxShadow,
          padding: "10px",
          borderRadius: "10px",
          position: "relative",
          width: "100%",
          height: "100%",
          bgcolor: "#ffffff",
        }}
      >
        {isLoading ? (
          <div className="text-center">
            <BeatLoader />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-1">
                  <label className="mb-2">
                    <DateRange sx={{ fontSize: "18px" }} /> কার্যক্রম -{" "}
                    {localDateFormat(new Date())}
                  </label>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রেরিত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {data?.data?.total?.sent_files}
                      </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <label>প্রাপ্ত নথি</label>
                      </div>
                      <Badge bg="primary" pill>
                        {data?.data?.total?.received_files}
                      </Badge>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default FileHistoryCount;
