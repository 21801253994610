import {
  APPLICATION_AGREEMENT_GET,
  APPLICATION_AGREEMENT_SEND,
  APPLICATION_GET,
  APPLICATION_SEND,
  COPYRIGHT_AGREEMENT_COUNT,
  GET_BY_ROLE,
  IS_DISTRIBUTOR,
} from "../constants";
import { apiSlice } from "./apiSlice";

const applicationCopyrightApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // COPYRIGHT
    getApplicationCopyright: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
      }) => {
        let url = `${APPLICATION_GET}?per_page=100`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["applicationsCopyright"],
    }),
    sendApplicationCopyright: builder.mutation({
      query: (formData) => ({
        url: APPLICATION_SEND,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["applicationsCopyright"],
    }),
    // AGREEMENT
    getApplicationAgreement: builder.query({
      query: ({
        date = "",
        trackReg = "",
        category_id = "",
        subCategory = "",
        page = 1,
      }) => {
        let url = `${APPLICATION_AGREEMENT_GET}?per_page=100`;
        if (date) {
          url += `&date=${date}`;
        }
        if (trackReg) {
          url += `&trackReg=${trackReg}`;
        }
        if (category_id) {
          url += `&category_id=${category_id}`;
        }
        if (subCategory) {
          url += `&subCategory=${subCategory}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        return url;
      },
      providesTags: ["applicationsAgreement"],
    }),
    sendApplicationAgreement: builder.mutation({
      query: (formData) => ({
        url: APPLICATION_AGREEMENT_SEND,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["applicationsAgreement"],
    }),
    // common

    getCopyrightAgreementCount: builder.query({
      query: ({ role_id = "", fileStatus = "", appType = "" }) => {
        let url = `${COPYRIGHT_AGREEMENT_COUNT}?`;
        const params = [];

        if (role_id) {
          params.push(`role_id=${role_id}`);
        }
        if (fileStatus) {
          params.push(`fileStatus=${fileStatus}`);
        }
        if (appType) {
          params.push(`appType=${appType}`);
        }

        url += params.join("&");
        return url;
      },
      keepUnusedDataFor: 10,
    }),

    getByRole: builder.query({
      query: (ids) => `${GET_BY_ROLE}?role_id=${ids}`,
    }),
    // is distributed
    getIsDistributor: builder.query({
      query: () => IS_DISTRIBUTOR,
      keepUnusedDataFor: 2,
    }),
  }),
});

export const {
  // copyright
  useGetApplicationCopyrightQuery,
  useSendApplicationCopyrightMutation,
  // agreement
  useGetApplicationAgreementQuery,
  useSendApplicationAgreementMutation,
  // common
  useGetCopyrightAgreementCountQuery,
  useGetByRoleQuery,

  useLazyGetByRoleQuery,
  useGetIsDistributorQuery,
} = applicationCopyrightApiSlice;
