import React, { useState } from "react";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import { Home, Report } from "@mui/icons-material";
import { useGetUserFileCountQuery } from "../../../../redux/api/reportApiSlice";
import Loader from "../../../../components/custom/Loader";
import { Badge } from "react-bootstrap";
import UserFileCountFilter from "./UserFileCountFilter";
import ActivityHistoryCount from "./ActivityHistoryCount";
import { Typography } from "@mui/material";

const UserFileCount = () => {
  const [show, setShow] = useState(false);
  const [viewData, setViewData] = useState("");
  const [user_id, setUserId] = useState("");
  const [role_id, setRoleId] = useState("");
  const [appType, setAppType] = useState("");
  const { data, isLoading } = useGetUserFileCountQuery({
    role_id,
    user_id,
    appType,
  });

  const breadcrumbsData = [
    { icon: Home, title: "হোম" },
    { icon: Report, title: "নথি রিপোর্ট" },
    { icon: Report, title: "নথি পর্যবেক্ষণ", active: true },
  ];

  if (isLoading) {
    return <Loader />;
  }

  const mainData = data?.data;

  return (
    <div>
      <div className="py-2">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>

      <div className="slideUp dashboard-layout p-3">
        <div>
          <UserFileCountFilter
            user_id={user_id}
            setUserId={setUserId}
            role_id={role_id}
            setRoleId={setRoleId}
            appType={appType}
            setAppType={setAppType}
          />
        </div>
        <Typography fontWeight="bold">ব্যবহারকারী এবং নথির সংখ্যা </Typography>
        <div className="row pt-3">
          {mainData?.length > 0 ? (
            mainData?.map((item, i) => (
              <div className="col-md-3">
                <div
                  key={i}
                  className="card mb-3 cursor-pointer"
                  onClick={() => {
                    setShow(true);
                    setViewData(item);
                  }}
                >
                  <span className="d-flex justify-content-between justify-items-center">
                    <label className="mx-2 p-2 cursor-pointer">{item?.name}</label>
                    <span className="border-start py-2 px-3 fw-semibold">
                      {item?.stored_file_count}
                    </span>
                  </span>
                </div>
              </div>
            ))
          ) : (
            <label>No data found</label>
          )}
        </div>
      </div>
      <ActivityHistoryCount
        show={show}
        data={viewData}
        onHide={() => {
          setShow(false);
          setViewData("");
        }}
      />
    </div>
  );
};

export default UserFileCount;
